import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { Dashboard, Inventory, Purchase, QualityManagement } from "containers/Pages/InventoryManagement/pages";
import Category from "containers/Pages/InventoryManagement/pages/admin/Inventory/Category";
import CreateProduct from "containers/Pages/InventoryManagement/pages/admin/Inventory/CreateProduct";
import { SPM_ROOT_PATH, SPM_ROUTES } from "containers/Pages/StockAndProductionManagement/SPMRoutes";
import StockAndProductionManagement from "containers/Pages/StockAndProductionManagement";
import { ACGProductOrders, CustomerOrders, DistributionOrders, ECommerceOrders, CrossDocking } from "containers/Pages/StockAndProductionManagement/pages";

const Right = styled.div`
  height: 100%;
  padding: 0 0.5em 0.5em 0.5em;
  border-radius: 5px;
  margin-right: 1em;
  margin-left: 1em;
`;


const PageLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <StockAndProductionManagement />
    <Right>{children}</Right>
  </>
);

const StockAndProductionRouting = () => {
  // console.log('SPM_ROUTES ', SPM_ROOT_PATH);

  return (
    <Routes>
      <Route
        path={SPM_ROOT_PATH}
        element={<Navigate to={`${SPM_ROOT_PATH}/dashboard`} />}
      />
      <Route
        path={SPM_ROUTES.dashboard}
        element={<PageLayout><Dashboard /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.procurement}
        element={<PageLayout><Purchase /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.qualityManagement}
        element={<PageLayout><QualityManagement /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.inventory}
        element={<PageLayout><Inventory /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.inventoryProductsCategory}
        element={<PageLayout><Category /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.inventoryCreateProduct}
        element={<PageLayout><CreateProduct /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.acgProductOrders}
        element={<PageLayout><ACGProductOrders /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.distributionOrders}
        element={<PageLayout><DistributionOrders /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.eCommerceOrders}
        element={<PageLayout><ECommerceOrders /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.customerPortalOrders}
        element={<PageLayout><CustomerOrders /></PageLayout>}
      />
      <Route
        path={SPM_ROUTES.crossDocking}
        element={<PageLayout><CrossDocking /></PageLayout>}
      />

    </Routes>
  );
};

export default StockAndProductionRouting;