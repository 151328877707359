import React, { useEffect, useMemo, useState } from 'react'
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons'
import SimpleButton from 'components/GlobalButtons/SimpleButton'
import { HeadingLabel, } from 'statics/styles/StyledComponents'
import styled from 'styled-components'
import AddQueryCriteriaModal from './AddQueryCriteriaModal'
import AdiTable from 'components/AdiTable'
import { formatTitle, initialCellValue, initialDateValueWithTimeZone } from 'utilities/Functions/FormatFunctions'
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS'
import EventInfoModal from '../Track/Modal/EventInfoModal'
import TableSearchFilter from './TableSearchFilter'
import { convertStepAndBizLocation } from '../Utils/functions'

import { Tag, Typography } from 'antd'
import { useTraceDataAndFilters } from '../hooks'
import { useGeneralStore } from 'zustand-stores';
import { useLocation } from 'react-router'
import TableCard from 'components/AdiTable/TableCard'
import { GeneralStore } from "zustand-stores/useGeneralStore";

type criteriaTypes = {
  [key: string]: string;
};

const TraceBy = () => {
  const location = useLocation();
  const isGlobalLoading = useGeneralStore.getState().isGlobalLoading
  const dataHandler = useTraceDataAndFilters()
  const setIsGlobalLoading = useGeneralStore(
    (state: GeneralStore) => state.setIsGlobalLoading
  );

  const [criteriaSelection, setCriteriaSelection] = useState<criteriaTypes>({});
  const [filterSelection, setFilterSelection] = useState<any>(null);

  const [modalOpen, setModalOpen] = React.useState({
    open: false,
    selectedData: null,
    originalData: null,
    type: "",
  });

  const [openModal, setOpenModal] = useState<any>({
    open: false,
    data: null,
  });

  const [filterData, setFilterData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsGlobalLoading(true);
        const combinedFilter = {
          ...filterSelection,
          ...criteriaSelection,
        };

        // if object is empty then return
        if (Object.keys(combinedFilter).length === 0) {
          setFilterData([]);
          return;
        }

        await getFiltersData(combinedFilter);
      } finally {
        setIsGlobalLoading(false);
      }
    };
    fetchData();
  }, [criteriaSelection]);

  const getFiltersData = async (values: any) => {
    const result = await dataHandler.getFilteredData(values);
    const tableData = dataHandler.constructTableData(result);

    const sortedEventList = tableData?.sort((a: any, b: any) => {
      return a.intEventID.localeCompare(b.intEventID);
    });

    if (Array.isArray(sortedEventList)) {
      setFilterData([...sortedEventList]);
    }
  };

  const closeModal = () => {
    setModalOpen({
      ...modalOpen,
      open: false,
      selectedData: null,
    });
  };

  const onCloseModal = () => {
    setOpenModal({
      ...openModal,
      open: false,
    });
  };

  const handleRowClick = (record: any) => {
    setOpenModal({
      ...openModal,
      open: true,
      data: record,
    });
  };

  console.log('filterSelection', filterSelection, criteriaSelection, filterData)

  const isDisplayNoDataMessage = useMemo(() => {

    const combinedFilter = {
      ...criteriaSelection,
      ...filterSelection,
    }

    return Object.keys(combinedFilter).some(key => combinedFilter[key]) && filterData.length === 0


  }, [filterSelection, criteriaSelection, filterData])

  console.log('isDisplayNoDataMessage', isDisplayNoDataMessage, isGlobalLoading)

  return (
    <>
      {/* <FilterContainer> */}
      {/* <HeadingLabel>Trace Scenario</HeadingLabel> */}
      <TableCard >
        <div style={{
          margin: '0 10px'
        }}>
          <TableSearchFilter
            setFilterData={setFilterData}
            EPC={location?.state?.epc}
            setFilterSelection={setFilterSelection}
            setCriteriaSelection={setCriteriaSelection}
            filterSelection={filterSelection}
          />
          {/* </FilterContainer> */}
          <SimpleButton
            text={
              <BtnText>
                <PlusOutlined />
                &nbsp;&nbsp;Add Query Criteria
              </BtnText>
            }
            onClick={() => {
              setModalOpen({
                ...modalOpen,
                open: true,
                type: 'addEPC',
              })
            }}
            disabled={!filterSelection}
            style={{ marginTop: '20px' }}
            outlined
          />
          {criteriaSelection &&
            <div style={{ marginTop: '20px' }}>
              {Object.entries(criteriaSelection).map(([key, item]) => (
                <Tag
                  key={key}
                  closable
                  closeIcon={<CloseCircleOutlined />}
                  onClose={() => {
                    const newCriteriaSelection = { ...criteriaSelection };
                    delete newCriteriaSelection[key];
                    setCriteriaSelection(newCriteriaSelection);
                  }}
                >
                  {item}
                </Tag>
              ))}
            </div>
          }
        </div>
        {
          isDisplayNoDataMessage && !isGlobalLoading ? <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}><Typography.Title level={5} style={{ marginTop: '20px', color: 'white' }}>There is no data matching your trace criteria</Typography.Title></div>
            :
            <>
              {Array.isArray(filterData) && filterData.length > 0 &&
                <AdiTable
                  columns={traceColumns}
                  onRowClick={(record: any) => handleRowClick(record)}
                  tableData={filterData}
                  rowKey={(record: any) => record?.key}
                  fullWidth
                  marginTop='30px'
                  extraPaginationPadding
                />
              }
            </>
        }

      </TableCard>
      <AddQueryCriteriaModal
        formName="addCriteriaForm"
        open={modalOpen.open}
        onClose={closeModal}
        selectedData={modalOpen.selectedData}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        setCriteriaSelection={setCriteriaSelection}
      />
      <EventInfoModal
        open={openModal.open}
        onClose={onCloseModal}
        selectedEvent={openModal.data}
      />
    </>
  )
}

export default TraceBy;

const traceColumns = [
  {
    title: formatTitle("Event ID"),
    dataIndex: "intEventID",
    key: "intEventID",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Event Type"),
    dataIndex: "type",
    key: "type",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Action"),
    dataIndex: "action",
    key: "action",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle("Event Time"),
    dataIndex: "eventTime",
    key: "eventTime",
    ...TABLE_CELL_CONFIG,
    render: initialDateValueWithTimeZone,
  },
  {
    title: formatTitle("Business Step"),
    dataIndex: "biz_step",
    key: "biz_step",
    ...TABLE_CELL_CONFIG,
    render: (text: string) => {
      return text ? convertStepAndBizLocation(text) : initialCellValue;
    },
  },
  {
    title: formatTitle("Business Location"),
    dataIndex: "biz_Location",
    key: "biz_Location",
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
];

const FilterContainer = styled.div`
  width: 100%;
  border: 1px solid var(--input-border-color) !important;
  padding: 20px;
  border-radius: 5px;
`;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;
