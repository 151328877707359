/* eslint-disable react/no-unescaped-entities */
import DisplayStats from "components/Statistics/DisplayStats";
import { OrderHeader, PurchaseListingTable } from "containers/Pages/InventoryManagement/components";
import useTableFilterss, { FilterType } from "containers/Pages/InventoryManagement/hooks/usetableFilters";
import React, { useState, useEffect, useMemo } from "react";
import { DisplayEmptyTextPrimary } from "statics/styles/StyledComponents";
import styled from "styled-components";
import { formatTitle } from "utilities/Functions/FormatFunctions";
import { ADMIN_TABS } from '../../../'


import { PurchaseOrderAPI } from "containers/Pages/InventoryManagement/apis";
import { SOMPageView } from "containers/Pages/InventoryManagement/statics/types";
import FlowInfoPanel from "components/FlowInfoPanel";
import { PROCUREMENT_FLOW_STEPS } from "utilities/CONSTANTS";
import TableCard from "components/AdiTable/TableCard";
import EmptyDisplay from "components/AdiTable/EmptyDisplay";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import ClosedOrdersIcon from '../../../../../../assets/analytics/inventory-management/closed_orders.svg';
import OpenOrdersIcon from '../../../../../../assets/analytics/inventory-management/open_orders.svg';
import PartiallyDeliveredIcon from '../../../../../../assets/analytics/inventory-management/partially_delivered.svg';
import TotalOrdersIcon from '../../../../../../assets/analytics/inventory-management/total_orders.svg';
import { Analytics } from 'components/Analytics';
import { ProcessMap } from 'components/ProcessMap';

type Props = {
	setSelectedPurchaseOrder: Function,
	setIsEdit: Function,
	setIsView: Function,
	pageType?: string,
	setIsReject: Function,
	setIsReceiveView: Function,
	navigatePageView: Function,
	// isRejectView: boolean,
	// setIsRejectView: Function,
};

const columnSelectOptions = [
	{
		text: formatTitle('PAYMENT TERMS'),
		valueKey: 'payment_terms',
	},
	{
		text: formatTitle("PAYMENT DUE DATE"),
		valueKey: "due_date",
	},
]

const defaultSelectColumns = columnSelectOptions.map((column: any) => column.valueKey);

const PurchaseListing = (props: Props) => {
	const { pageType, setSelectedPurchaseOrder, setIsEdit, setIsView, setIsReject, setIsReceiveView, navigatePageView
	} = props;
	// custom hook that handles all the filter logic
	const [filterState, filterDispatch] = useTableFilterss('Procurement');
	const [isLoading, setIsLoading] = useState(false);
	const [showTableColumn, setShowTableColumn] = useState<any>(defaultSelectColumns);
	const [selectedStatus, setSelectedStatus] = useState<string>('all');
	const [filteredData, setFilteredData] = useState<any[]>(filterState.filteredData);


	const getTableData = () => {
		// dispatch(actions.setLoading(true))
		// const url = `/purchase-orders`
		setIsLoading(true)
		PurchaseOrderAPI.getAllPurchaseOrders().then(res => {

			const result = res
			Array.isArray(result) && result.sort((a: any, b: any) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())
				.forEach((row: any, index: number) => {
					row.key = index + 1
				})
			filterDispatch({
				type: FilterType.APPLY_ALL,
				payload: result
			})

		}).catch(err => {
			console.log('getTableData purchase fail...', err)

		}).finally(() => {
			// dispatch(actions.setLoading(false))
			setIsLoading(false)
		})
	}

	useEffect(() => {
		getTableData()

		setFilteredData(filterState.filteredData)
	}, []);


	const handleColumnSelect = (e: any) => {
		setShowTableColumn(e)
	}


	const statsData = useMemo(() => {
		const openStatus = ['pending', 'confirmed', 'sent']
		const closedStatus = ['fully_delivered', 'rejected']
		const partiallyDeliveredStatus = ['partially_delivered']

		const data = filterState.purchaseOrderData || []

		const open = data.filter((order: any) => openStatus.includes(order.order_status)).length
		const closed = data.filter((order: any) => closedStatus.includes(order.order_status)).length
		const partiallyDelivered = data.filter((order: any) => partiallyDeliveredStatus.includes(order.order_status)).length
		const totalOrders = data.length

		return [
			{
				text: 'Total Orders',
				number: totalOrders,
			},
			{
				text: 'Open Orders',
				number: open,
			},
			{
				text: 'Closed Orders',
				number: closed,
			},
			{
				text: 'Partially Delivered',
				number: partiallyDelivered,
			},
		]
	}, [filterState.purchaseOrderData])

	const getValueByTitle = (title: string) => {
		const item = statsData?.find((item: any) => item.text === title);
		return item ? item.number : 0;
	};

	const handleAnalyticsClick = (status: string) => {
		setSelectedStatus(status);
	};

	useEffect(() => {
		if (selectedStatus) {
			if (selectedStatus === 'all') {
				setFilteredData(filterState.filteredData);
			} else if (selectedStatus === 'open-orders') {
				// all record removing fully_delivered and partially_delivered 
				const filtered = filterState.filteredData.filter(
					(item: any) => item.order_status !== 'fully_delivered' && item.order_status !== 'partially_delivered'
				);
				setFilteredData(filtered);
			} else {
				const filtered = filterState.filteredData.filter((item: any) => item.order_status === selectedStatus);
				setFilteredData(filtered);
			}
		} else { setFilteredData(filterState.filteredData); }
	}, [selectedStatus, filterState.filteredData]);

	const analyticsInfo = [
		{
			icon: <img src={TotalOrdersIcon} alt="AllGridIcon" />,
			title: 'Total Orders',
			value: getValueByTitle('Total Orders'),
			onClick: () => handleAnalyticsClick('all'),
		},
		{
			icon: <img src={OpenOrdersIcon} alt="AllGridIcon" />,
			title: 'Open Orders',
			value: getValueByTitle('Open Orders'),
			onClick: () => handleAnalyticsClick('open-orders'),
		},
		{
			icon: <img src={ClosedOrdersIcon} alt="AllGridIcon" />,
			title: 'Closed Orders',
			value: getValueByTitle('Closed Orders'),
			onClick: () => handleAnalyticsClick('fully_delivered'),
		},
		{
			icon: <img src={PartiallyDeliveredIcon} alt="AllGridIcon" />,
			title: 'Partially Delivered',
			value: getValueByTitle('Partially Delivered'),
			onClick: () => handleAnalyticsClick('partially_delivered'),
		},
	];

	return (
		<>
			<div style={{ marginBottom: '20px' }}>
				{/* <FlowInfoPanel
					items={[...PROCUREMENT_FLOW_STEPS]}
					style={{ minWidth: '1100px', marginLeft: '32px' }}
				/> */}
				<ProcessMap
					items={[...PROCUREMENT_FLOW_STEPS.map((item: any) => item.label)]} />
				{/* <FlowInfoPanel
					items={['Place Supplier Order', 'Approve Order', 'Send Order to Supplier', 'Receive or Return Order']}
				/> */}
				{/* <DisplayStats data={statsData} /> */}
				<div style={{ marginBottom: '20px' }}>
					<Analytics items={analyticsInfo} />
				</div>
			</div>


			{
				!isLoading && filterState.filteredData.length === 0 ? (
					<EmptyDisplay data={{
						title: 'Start managing your Supplier activities', text: 'Create, customize, and send Purchase Orders to your suppliers',
						buttons: [<SimpleButton
							text='+ Create Supplier Order'
							onClick={() => navigatePageView(SOMPageView.CreateView)}
						/>],
					}} />
				) : (
					<TableCard>
						<OrderHeader
							onTextSearch={(text: string) => filterDispatch({ type: FilterType.SEARCH_TEXT, payload: text })}
							onDateChange={(date: [string, string]) => filterDispatch({ type: FilterType.ORDERED_DATE, payload: date })}
							pageType={pageType}
							onTableColumnSelect={handleColumnSelect}
							selectedTablesColumns={showTableColumn}
							columnSelectOptions={columnSelectOptions}
							goToCreateView={() => navigatePageView(SOMPageView.CreateView)}
						/>
						<PurchaseListingTable
							data={filteredData}
							setSelectedPurchaseOrder={setSelectedPurchaseOrder}
							setIsEdit={setIsEdit}
							setIsView={setIsView}
							selectedTableColumns={showTableColumn}
							getTableData={getTableData}
							setIsReject={setIsReject}
							setIsReceiveView={setIsReceiveView}
							isLoading={isLoading}
							navigatePageView={navigatePageView}
						/>
					</TableCard>

				)
			}



		</>
	);
};

export default PurchaseListing;

const DisplaySecondaryText = styled.p`
color: var(--white-text-color);
  font-size: 16px;
  text-align: center;
  margin-top: 18px;
  width: 25%;
  margin-left: 50%;
  transform: translateX(-50%);
`;

