import DisplayStats from 'components/Statistics/DisplayStats';
import React, { useEffect, useMemo, useState } from 'react'
import AdiTable from 'components/AdiTable';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue, initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import styled from 'styled-components';
import { theme } from 'statics/styles/StyledComponents';
import internalChain from '../statics/images/internalChain.svg';
import externalChain from '../statics/images/externalChain.svg';
import ValueChainAPI from '../apis/ValueChainAPI'
import { convertUTCtoMoment } from "../Utils/functions";
import { checkWindowSelectedText, getBizCode } from "utilities/Functions/GlobalHelperFunctions";
import { VCT_ROOT_PATH } from "../VCTRoutes";
import { useNavigate } from "react-router-dom";
import TableCard from 'components/AdiTable/TableCard';
import { Analytics } from 'components/Analytics';
import TotalChainIcon from '../../../../assets/analytics/value-chain/total_chain.svg';
import ChainsInProgressIcon from '../../../../assets/analytics/value-chain/chain_in_progress.svg';
import ChainsCompletedIcon from '../../../../assets/analytics/value-chain/chain_completed.svg';

type OriginalProps = {
  tabValue: string;
  customizedColumn?: any[];
};

const Tab = {
  INTERNAL_CHAIN: "Internal Chain",
  EXTERNAL_CHAIN: "External Chain",
};

const STATUS = {
  close: 'close',
  open: 'open',
}

type Props = OriginalProps;

const InternalChainTable = (props: Props) => {
  const { tabValue, customizedColumn } = props;
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [analyticData, setAnalyticData] = React.useState<any>({
    totalChains: 0,
    chainsInProgress: 0,
    chainsCompleted: 0,
    totalEventsRecorded: 0,
    packsCreated: 0,
  });

  const [selectedStatusChain, setSelectedStatusChain] = useState<string | null>(null);

  const BIZ_CODE = getBizCode()
  const isAcgOrAccUser = useMemo(() => BIZ_CODE === 'acg' || BIZ_CODE === 'acc' ? true : false, [BIZ_CODE])

  const _columns = customizedColumn ? customizedColumn : columns

  useEffect(() => {
    setIsDataLoading(true)
    setSelectedStatusChain(null)

    ValueChainAPI.getSupplyChain().then((response: any) => {
      if (response) {
        let data = []
        if (tabValue === Tab.INTERNAL_CHAIN) {
          const internalChain = response.filter((item: any) => item?.internalProcess === 'True')
          data = internalChain
        } else if (tabValue === Tab.EXTERNAL_CHAIN) {
          const externalChain = response.filter((item: any) => item?.internalProcess === 'False')
          data = externalChain
        }
        const analyticData = getAnalyticData(data)

        setAnalyticData(analyticData)
        setTableData(data)
      }
      setIsDataLoading(false)
    })
  }, [tabValue]);


  const getAnalyticData = (data: any) => {
    const totalChains = data?.length || 0
    let totalEventsRecorded = 0
    let chainsCompleted = 0
    let chainsInProgress = 0

    for (const item of data) {
      console.log('totalEventsRecorded', totalEventsRecorded, item, item?.eventsRecorded)
      const eventsRecorded = item?.eventsRecorded;
      const eventsRecordedAsNumber = parseInt(eventsRecorded, 10);

      // Check if the parsed value is a valid number
      if (!isNaN(eventsRecordedAsNumber)) {
        totalEventsRecorded += eventsRecordedAsNumber;
      }
    }

    chainsCompleted = data?.filter((item: any) => item?.status === STATUS.close)?.length || 0
    chainsInProgress = data?.filter((item: any) => item?.status === STATUS.open)?.length || 0

    return {
      totalChains,
      chainsInProgress,
      chainsCompleted,
      totalEventsRecorded,
      packsCreated: 33,
    }

  }

  const statsData = () => {
    let statsData: any[] = [];
    if (tabValue === Tab.INTERNAL_CHAIN) {
      statsData = [
        {
          text: 'Total Chains',
          number: analyticData?.totalChains || 0,
        },
        {
          text: 'Chains In Progress',
          number: analyticData?.chainsInProgress || 0,
        },
        {
          text: 'Chains Completed',
          number: analyticData?.chainsCompleted || 0,
        },
        {
          text: 'Events Recorded',
          number: analyticData?.totalEventsRecorded || 0,
        },
        {
          text: 'Packs Created',
          number: isAcgOrAccUser ? 780 : 66,
        },
      ];
    } else {
      statsData = [
        {
          text: 'Total Chains',
          number: analyticData?.totalChains || 0,
        },
        {
          text: 'Chains In Progress',
          number: analyticData?.chainsInProgress || 0,
        },
        {
          text: 'Chains Completed',
          number: analyticData?.chainsCompleted || 0,
        },
        {
          text: 'Events Recorded',
          number: analyticData?.totalEventsRecorded || 0,
        },
      ];
    }
    return statsData
  };

  const handleRowClick = (record: any) => {
    console.log("handleRowClick record", record);
    navigate(`${VCT_ROOT_PATH}/events`, {
      state: {
        internalProcess: record?.internalProcess,
        instanceIdentifier: record?.instanceIdentifier,
      },
    })
  };

  const handleAnalyticsClick = (statusChain: string) => {
    if (statusChain === 'Total') {
      setSelectedStatusChain(null);
    } else {
      setSelectedStatusChain(statusChain);
    }
  };

  const filteredChainList = selectedStatusChain
    ? tableData.filter((chain: any) => chain.status === selectedStatusChain)
    : tableData;

  const analyticsData = [
    {
      icon: <img src={TotalChainIcon} alt="AllGridIcon" />,
      title: 'Total Chains',
      value: analyticData?.totalChains || 0,
      onClick: () => handleAnalyticsClick('Total'),
    },
    {
      icon: <img src={ChainsInProgressIcon} alt="AllGridIcon" />,
      title: 'Chains in Progress',
      value: analyticData?.chainsInProgress || 0,
      onClick: () => handleAnalyticsClick('open'),
    },
    {
      icon: <img src={ChainsCompletedIcon} alt="AllGridIcon" />,
      title: 'Chains Completed',
      value: analyticData?.chainsCompleted || 0,
      onClick: () => handleAnalyticsClick('close'),
    },
    // {
    //   icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
    //   title: 'Events Recorded',
    //   value: analyticData?.totalEventsRecorded || 0,
    //   routeTo: '',
    // },
    // {
    //   icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
    //   title: 'Packs Created',
    //   value: analyticData?.packsCreated || 780,
    //   routeTo: '',
    // },
  ];

  return (
    <>
      <ImgScroll>
        <ImgDiv2>
          <img src={tabValue === Tab.INTERNAL_CHAIN ? internalChain : externalChain} alt='Flow Diagram' style={{ width: '100%' }} />
        </ImgDiv2>
      </ImgScroll>
      {/* <div style={{ marginBottom: '20px' }}>
        <DisplayStats data={statsData()} />
      </div> */}
      <div style={{ marginBottom: '20px' }}>
        <Analytics
          key={tabValue}
          items={analyticsData}
          defaultSelected="Total Chains"
        />
      </div>
      <TableCard>
        <AdiTable
          tableData={filteredChainList}
          loading={isDataLoading}
          columns={_columns}
          fullWidth
          marginTop="20px"
          onRowClick={(record: any) => {
            if (checkWindowSelectedText()) return
            handleRowClick(record)
          }}
          extraPaginationPadding
        />
      </TableCard>
    </>
  )
}

export default InternalChainTable;

const columns = [
  {
    title: formatTitle('Chain ID'),
    dataIndex: 'instanceIdentifier',
    key: 'instanceIdentifier',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('Start Date'),
    dataIndex: 'startDate',
    key: 'startDate',
    ...TABLE_CELL_CONFIG,
    render: initialDateTimeValue,
  },
  {
    title: formatTitle('Status'),
    dataIndex: 'status',
    key: 'status',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => {
      return text ? capitaliseFirst(text) : initialCellValue
    }
  },
  {
    title: formatTitle('Events Recorded'),
    dataIndex: 'eventsRecorded',
    key: 'eventsRecorded',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
  },
  {
    title: formatTitle('End Date'),
    dataIndex: 'endChain',
    key: 'endChain',
    ...TABLE_CELL_CONFIG,
    render: (text: string) => {
      return convertUTCtoMoment(text)
    }
  }
];

const ImgScroll = styled.div`
  margin: 20px 0px 20px 0px;
  ${theme.myScrollbar}
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
`;

const ImgDiv2 = styled.div`
  display: flex;
  width: 1044px;
`;
