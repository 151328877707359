
import moment from "moment";

/// convert UTC to moment date and time
export const convertUTCtoMoment = (utc: string) => {
  return moment(utc).format("DD/MM/YYYY hh:mm");
}


export const convertStepAndBizLocation = (bizStepAndLocation: string) => {
  return bizStepAndLocation.split(":").pop();
}

// Event Modal > Event Collapse > For Read Point

export const getLatAndLng = (value: any) => {
  if (!value) return 'N/A'
  const regex = /([-+]?\d+\.\d+)/g;
  const matches = value?.match(regex);
  let lat = '';
  let lon = '';

  if (matches && matches.length >= 2) {
    lat = matches[0];
    lon = matches[1];
  }

  if (!lat || !lon) {
    return 'N/A'
  } else {
    return `Lat: ${lat}, Long: ${lon}`
  }
}

export const getGeoLocation = (value: any) => {
  console.log('geo location', value)
  if (!value) return 'N/A'
  const { lat, long } = value?.geo
  if (!lat || !long) return 'N/A'
  return `Lat: ${lat}, Long: ${long}`
}

export const convertEPCToBusinessLanguage = (epc: string): string => {
  if (!epc) return 'N/A'
  return epc.split(":").pop() as string;
}

// For Event Modal > For Map
export const toLatitudeAndLongitude = (value: any) => {
  if (!value) return { lat: '', long: '' }
  // const regex = /([-+]?\d+\.\d+)/g;
  // const matches = value.match(regex);
  let lat = '';
  let long = '';

  // if (matches && matches.length >= 2) {
  //   lat = matches[0];
  //   long = matches[1];
  // }
  if (value) {
    lat = value?.lat
    long = value?.long

  }
  return { lat, long }
}


export const isPetunaData = (isAcgUser: boolean, isAccUser: boolean, isKfUser: boolean) => {
  if (isAcgUser || isAccUser || isKfUser) {
    return false
  } else {
    return true
  }
}