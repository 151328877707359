/**
 * A Zustand store for managing authentication state globally.
 */

import { USER_ROLES, SESSION_STORAGE_KEYS } from 'utilities/CONSTANTS';
import { getBizCode, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import { create } from 'zustand'

/**
 * The keys of the access level object obtained after logging in.
 */
enum ACCESS_LEVEL_KEYS {
  admin = 'administrator',
  crm = 'crm',
  am = 'am',
  ins = 'insights',
  vct = 'vct',
  ce = 'ce',
  osm = 'osm',
  organicoliveoil = 'organicoliveoil', // "organicoliveoil" is for demo purposes only
}

/**
 * The original access level object obtained after logging in.
 */
type RawAccessLevel = { [value in ACCESS_LEVEL_KEYS]?: string }

type ModuleAccessType = {
  hasAccess: boolean;
  isAdmin: boolean;
  isReadOnly: boolean;
}

type State = {
  moduleAccess: {
    accessLevel: RawAccessLevel;
    admin: ModuleAccessType,
    crm: ModuleAccessType,
    am: ModuleAccessType,    // Asset Management
    ins: ModuleAccessType,   // Insights
    vct: ModuleAccessType,   // Value Chain Tracking
    ce: ModuleAccessType,    // Consumer Engagement
    osm: ModuleAccessType,   // OSM, Production Work Order,visitorManagement and CMS
  },
  isAcgUser: boolean;
  isAccUser: boolean;
  isKfUser: boolean;
  username: string;
}

type Action = {
  setAuth: (accessLevel: RawAccessLevel, bizCode: string, username: string) => void;
}

export type AuthStore = State & Action



/**
 * Processed the raw access level object to be an easy-to-use object.
 * @param moduleAccessLevel An object containing the access level for each module
 * @returns An object containing the easy-to-use access information for each module
 */
const getModuleAccess = (moduleAccessLevel?: RawAccessLevel): State['moduleAccess'] => {
  const accessLevel: RawAccessLevel = moduleAccessLevel || getSessionStorage(SESSION_STORAGE_KEYS.ACCESS_LEVEL, true);

  // Initial values
  const defaultValues = {
    hasAccess: false,
    isAdmin: false,
    isReadOnly: false,
  }
  let res: State['moduleAccess'] = {
    accessLevel: accessLevel,
    admin: defaultValues,
    crm: defaultValues,
    am: defaultValues,
    ins: defaultValues,
    vct: defaultValues,
    ce: defaultValues,
    osm: defaultValues,
  }

  if (!accessLevel) return res

  // If accessLevel is valid, return the access information for each module
  const _getAccess = (key: ACCESS_LEVEL_KEYS) => {
    return {
      hasAccess: accessLevel[key] !== undefined,
      isAdmin: accessLevel[key] === USER_ROLES.ADMIN,
      isReadOnly: accessLevel[key] === USER_ROLES.READ_ONLY,
    }
  }

  res = {
    ...res,
    admin: _getAccess(ACCESS_LEVEL_KEYS.admin),
    crm: _getAccess(ACCESS_LEVEL_KEYS.crm),
    am: _getAccess(ACCESS_LEVEL_KEYS.am),
    ins: _getAccess(ACCESS_LEVEL_KEYS.ins),
    vct: _getAccess(ACCESS_LEVEL_KEYS.vct),
    ce: accessLevel?.[ACCESS_LEVEL_KEYS.organicoliveoil] ? _getAccess(ACCESS_LEVEL_KEYS.organicoliveoil) : _getAccess(ACCESS_LEVEL_KEYS.ce),
    osm: _getAccess(ACCESS_LEVEL_KEYS.osm),
  }


  return res
}

const checkAcgOrAccUser = (type: string, bizCode?: string): boolean => {
  return bizCode === type || getBizCode() === type
}



/*************************************** Store Definition ***************************************/

const initialState: State = {
  moduleAccess: getModuleAccess(),
  isAcgUser: checkAcgOrAccUser('acg'),
  isAccUser: checkAcgOrAccUser('acc'),
  isKfUser: checkAcgOrAccUser('kf'),
  username: getSessionStorage(SESSION_STORAGE_KEYS.USER_NAME),
}


/**
 * A Zustand store for managing authentication state globally.
 * @returns An object containing the authentication state and a function to update it.
 */
const useAuthStore = create<AuthStore>((set) => ({
  ...initialState,
  setAuth: (accessLevel: RawAccessLevel, bizCode: string, username: string) => {
    set({
      moduleAccess: getModuleAccess(accessLevel),
      isAcgUser: checkAcgOrAccUser('acg', bizCode),
      isAccUser: checkAcgOrAccUser('acc', bizCode),
      username: username,
    })
  },
}))

export default useAuthStore

