import { logError } from "utilities/Functions/GlobalHelperFunctions";
import { VALUE_CHAIN_API } from "utilities/AdiApi";
import { getBizCode } from "utilities/Functions/GlobalHelperFunctions";

let idsCache: any = null;
let idsCacheTimestamp: number | null = null;
let epcIdsCache: any = null;
let epcIdsCacheTimestamp: number | null = null;
let eventTypeCache: any = null;
let eventTypeCacheTimestamp: number | null = null;


const CACHE_KEY_PREFIX = 'valuechain_epcs_cache_';
const CACHE_DURATION = 60 * 60 * 1000; // 60 minutes in milliseconds

const EpcAPI = {
  getAll: async () => {
    const bizCode = getBizCode();
    const cacheKey = `${CACHE_KEY_PREFIX}${bizCode}_all`;

    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { timestamp, data } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }

    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}`);
      localStorage.setItem(cacheKey, JSON.stringify({
        timestamp: Date.now(),
        data: res.data.body
      }));
      return res.data.body;
    } catch (e) {
      logError(e);
      throw e;
    }
  },

  getIds: async () => {
    if (epcIdsCache && epcIdsCacheTimestamp && (Date.now() - epcIdsCacheTimestamp) < CACHE_DURATION) {
      return epcIdsCache;
    }

    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?epcs`)
      epcIdsCache = res.data;
      epcIdsCacheTimestamp = Date.now();
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getEpcsByEventType: async (eventType: string) => {
    // if (eventTypeCache && eventTypeCacheTimestamp && (Date.now() - eventTypeCacheTimestamp) < CACHE_DURATION) {
    //   return eventTypeCache;
    // }

    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?eventType=${eventType}`)
      eventTypeCache = res.data;
      eventTypeCacheTimestamp = Date.now();
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getEpcsByBatchNumber: async (batchNumber: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?batchNumber=${batchNumber}`)
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getIntEventIds: async () => {
    if (idsCache && idsCacheTimestamp && (Date.now() - idsCacheTimestamp) < CACHE_DURATION) {
      return idsCache;
    }

    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?intEventIDs`)
      idsCache = res.data;
      idsCacheTimestamp = Date.now();
      return res.data
    } catch (e) {
      logError(e)
      throw e
    }
  },

  getById: async (refId: string) => {
    const bizCode = getBizCode()
    try {
      const res = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?refID=${refId}`)
      return res.data.body[0]
    } catch (e) {
      logError(e)
      throw e
    }
  },

  clearCache: () => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(CACHE_KEY_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  },

  getEventByEpc: async (epc: string) => {
    const bizCode = getBizCode();
    try {
      const response = await VALUE_CHAIN_API.get(`valuechain_epcs/${bizCode}?epc=${epc}`);
      return response.data.body;
    } catch (error) {
      logError(error);
      throw error;
    }
  }
}

export default EpcAPI