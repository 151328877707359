import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, message, Row, Tooltip } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import React, { useState, useEffect } from 'react'
import { DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from 'statics/styles/StyledComponents';
import { getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import styled from 'styled-components';
import AdiTable from 'components/AdiTable';
import { STOCK_FLOW_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { capitaliseFirst, formatTitle, initialCellValue, initialDatellValue } from 'utilities/Functions/FormatFunctions';
import AddInventoryItemAPI from 'containers/Pages/InventoryManagement/apis/AddInventoryItemAPI';
import { AddInventoryItemForm, ReceiveOrderBarcodeModal, InventoryDisplayDataModal } from 'containers/Pages/InventoryManagement/components';


import { BatchTrackingAPI, FinishedProductAPI, SupplierAPI } from 'containers/Pages/InventoryManagement/apis';
import { ITEM_LOCATION_LIST } from 'containers/Pages/InventoryManagement/statics/ItemLocationIDs';
import { ColumnOptionsType } from 'components/GlobalButtons/ColumnSelectButton';
import CategoryAPI from 'containers/Pages/InventoryManagement/apis/CategoryAPI';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { PRODUCT_TYPE } from '../../../statics/constants'
import TableCard from 'components/AdiTable/TableCard';



const selectColumnOptions: ColumnOptionsType = [
  {
    text: formatTitle("Cost price/Unit"),
    valueKey: "cost_price",
  },
  {
    text: formatTitle("Selling Price"),
    valueKey: "selling_price",
  },

  {
    text: formatTitle('Lead Delivery Time'),
    valueKey: 'lead_time_for_replenishement'
  },
  {
    text: formatTitle('Supplier'),
    valueKey: 'supplier_name'
  },
  {
    text: formatTitle('Date Of Last Purchase'),
    valueKey: 'date_of_last_purchase'
  }
]

const defaultTablesColumns = selectColumnOptions.map((item: any) => item.valueKey)

type Props = {
  active: boolean,
}

const AllInventory = (props: Props) => {
  const { active } = props
  const [fullTableData, setFullTableData] = useState<any>({
    general: [],
    ecom: [],
    distribution: [],
    acg: [],
  })

  const [filterData, setFilterData] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [filtersLists, setFiltersLists] = useState<{ [key: string]: string[] }>({
    suppliers: [],
    categories: [],
    subCategories: [],
  })

  const [filterDropdownState, setDropdownState] = useState<any>({
    value: PRODUCT_TYPE.generalProduct.value,
    label: PRODUCT_TYPE.generalProduct.label,
  })

  const [selectedTableColumns, setSelectedTableColumns] = useState<any[]>(defaultTablesColumns);

  const [addItemModal, setAddItemModal] = useState<any>({
    open: false,
    transferLocation: false,
    bypass: false,
    data: {},
  })
  const [barcodeModal, setBarcodeModal] = useState({
    open: false,
    record: null,
  })
  const [productModalData, setProductModalData] = useState<{ type: 'inventory', value: string, productData: null }>({
    type: 'inventory',
    value: '',
    productData: null,
  })
  const [isLoading, setIsLoading] = useState(false)




  useEffect(() => {
    if (active) {
      setIsLoading(true)
      Promise.all([
        getProductsData(),
        getFilterListData()
      ]).finally(() => {
        setIsLoading(false)
      })
    }
  }, [active])

  const getFilterListData = async () => {
    const filterList: any = {
      suppliers: [],
      categories: [],
      subCategories: [],
    }
    try {
      const suppliers = await SupplierAPI.getAllSuppliers()
      if (Array.isArray(suppliers)) {
        const names: any = suppliers.map((item: any) => item.bus_name)
        names.sort((a: any, b: any) => a.localeCompare(b))
        filterList.suppliers = names.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get supplier list error ...', e)
    }
    try {
      // get categories
      const categories = await CategoryAPI.getAllCategories()
      const cateNames = categories.map((item: any) => item.item_category)
      if (Array.isArray(cateNames)) {
        cateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.categories = cateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item categories error...', e)
    }
    try {
      // get sub categories
      const subCategories = await CategoryAPI.getAllSubCategories()
      const subCateNames = subCategories.map((item: any) => item.item_sub_category)
      if (Array.isArray(subCateNames)) {
        console.log('subCateNames', subCateNames)
        subCateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.subCategories = subCateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item sub categories error...', e)
    }
    setFiltersLists({ ...filterList })

  }




  const getProductsData = async () => {
    try {

      const generalProducts = await AddInventoryItemAPI.getAllStockOverviewItems()
      const distributionProducts = await FinishedProductAPI.getFinishedProductsByType(PRODUCT_TYPE.xplProduct.value)
      const ecomProducts = await FinishedProductAPI.getFinishedProductsByType(PRODUCT_TYPE.eCommerceProduct.value)
      // const acgProducts = await FinishedProductAPI.getFinishedProductsByType(PRODUCT_TYPE.acgProduct.value)
      const acgProducts = await BatchTrackingAPI.getProductsByType(PRODUCT_TYPE.acgProduct.value)

      setFullTableData((prev: any) => ({
        ...prev,
        general: generalProducts || [],
        ecom: ecomProducts || [],
        distribution: distributionProducts || [],
        acg: acgProducts || [],
      }))
    } catch (e) {
      console.log('get all products error...', e)
      message.error('Failed to get stock products data.')
    }
  }



  console.log('table data', filterData)


  useEffect(() => {
    let data: any[] = []
    if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      data = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      data = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      data = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      data = fullTableData.acg
    }

    onTextSearch(searchText, data)

  }, [fullTableData])


  const tableColumns = [
    {
      // this is fixed column
      title: formatTitle('Inventory CATEGORY'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: filtersLists.categories,
      onFilter: (value: string, record: any) => record.item_category === value,

    },
    {
      title: formatTitle("Inventory Subcategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: filtersLists.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },

    {
      title: formatTitle("Stock On Hand"),
      dataIndex: "stock_on_hand",
      key: "stock_on_hand",
      ...TABLE_CELL_CONFIG,
      // render: initialCellValue
      // if stock on hand less than reorder level as a Number, show red color
      render: (text: string, record: any,) => {
        if (text) {
          const stock = Number(record?.stock_on_hand)
          const reorder = Number(record?.reorder_level)
          console.log('stock', stock, reorder)
          if (stock < reorder) {
            return <Tooltip title="Stock on Hand is less than Reorder Level">
              <span style={{ color: 'red' }}>{text}</span>
            </Tooltip>
          }
        }
        return initialCellValue(Number(text))
      }
    },
    {
      title: "UOM",
      dataIndex: "measurement_unit",
      key: "measurement_unit",
      width: 160,
      render: initialCellValue
    },
    {
      title: formatTitle("Stock Allocated"),
      dataIndex: "stock_allocated",
      key: "stock_allocated",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Stock Dispatched"),
      dataIndex: "stock_dispatched",
      key: "stock_dispatched",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Reorder Level/Stock Alert Point"),
      dataIndex: "inventory_level",
      key: "inventory_level",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    // {
    //   title: formatTitle("Stock In Production"),
    //   dataIndex: "stock_allocated",
    //   key: "stock_allocated",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue
    // },
    // {
    //   title: formatTitle("Reorder Level"),
    //   dataIndex: "reorder_level",
    //   key: "reorder_level",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue
    // },
    // {
    //   title: formatTitle("Rejected Stock"),
    //   dataIndex: "rejected_stock",
    //   key: "rejected_stock",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue
    // },
    // {
    //   title: formatTitle("Expired Stock"),
    //   dataIndex: "expired_stock",
    //   key: "expired_stock",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue
    // },


  ]



  console.log('addItemModal', addItemModal)

  const finishedProductsColumns = [
    {
      // this is fixed column
      title: formatTitle('Inventory CATEGORY'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: filtersLists.categories,
      onFilter: (value: string, record: any) => record.item_category === value,

    },
    {
      title: formatTitle("Inventory Subcategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: filtersLists.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },
    {
      title: formatTitle("INTERNAL LOT NUMBER"),
      dataIndex: "internal_lot_number",
      key: "internal_lot_number",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("MANUFACTURING DATE"),
      dataIndex: "manufacturing_date",
      key: "manufacturing_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("EXPIRY DATE"),
      dataIndex: "expiry_date",
      key: "expiry_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("STOCK ON HAND"),
      dataIndex: "stock_on_hand",
      key: "stock_on_hand",
      ...TABLE_CELL_CONFIG,
      render: (text: string, record: any,) => {
        if (text) {
          const stock = record?.stock_on_hand
          const reorder = Number(record?.reorder_level)
          console.log('stock', stock, reorder)
          if (stock < reorder) {
            return <Tooltip title="Stock on Hand is less than Reorder Level">
              <span style={{ color: 'red' }}>{text}</span>
            </Tooltip>
          }
        }
        return initialCellValue(text)
      }
    },
    {
      title: formatTitle("STOCK ALLOCATED"),
      dataIndex: "stock_allocated",
      key: "stock_allocated",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Reorder Level"),
      dataIndex: "reorder_level",
      key: "reorder_level",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    // {
    //   title: formatTitle("STOCK DISPATCHED"),
    //   dataIndex: "stock_dispatched",
    //   key: "stock_dispatched",
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    // },
    {
      title: formatTitle("Rejected Stock"),
      dataIndex: "rejected_stock",
      key: "rejected_stock",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    {
      title: formatTitle("Expired Stock"),
      dataIndex: "expired_stock",
      key: "expired_stock",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue
    },
    // {
    //   title: formatTitle('Action'),
    //   dataIndex: 'action',
    //   key: 'action',
    //   fixed: 'right',
    //   align: 'center',
    //   width: 120,
    //   render: (text: any, record: any, index: number) => {
    //     return (
    //       <div
    //         style={{ cursor: 'pointer' }}
    //         onClick={(e) => {
    //           e.stopPropagation()
    //           setBarcodeModal({
    //             ...barcodeModal,
    //             open: true,
    //             record: record,
    //           })
    //         }}>
    //         <Tooltip title="Print Barcode">
    //           <PrinterOutlined style={{ fontSize: '18px' }} />
    //         </Tooltip>
    //       </div>
    //     )
    //   }
    // }
  ]


  const acgProductColumns = [
    {
      title: formatTitle("PickList No."),
      dataIndex: "picklist_ID",
      key: "picklist_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.picklist_ID?.localeCompare(b.picklist_ID)
    },
    {
      title: formatTitle("Order No."),
      dataIndex: "order_ID",
      key: "order_ID",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      title: formatTitle('Inventory Category'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.categories,
      onFilter: (value: string, record: any) => record.item_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Inventory Subcategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      filters: filtersLists.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
      render: initialCellValue,
    },
    {
      title: formatTitle("Batch No"),
      dataIndex: "batch_number",
      key: "batch_number",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.batch_number?.localeCompare(b.batch_number)
    },
    {
      title: formatTitle("Processing Line"),
      dataIndex: "processing_line",
      key: "processing_line",
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
    },
    {
      title: formatTitle("MANUFACTURING DATE"),
      dataIndex: "manufacturing_date",
      key: "manufacturing_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle("Expiry DATE"),
      dataIndex: "expiry_date",
      key: "expiry_date",
      ...TABLE_CELL_CONFIG,
      render: initialDatellValue,
    },
    {
      title: formatTitle('LOCATION'),
      dataIndex: 'item_location',
      key: 'item_location',
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? capitaliseFirst(text) : initialCellValue(text),
      filters: ITEM_LOCATION_LIST.map((loc: any) => ({ text: capitaliseFirst(loc), value: loc })),
      onFilter: (value: string, record: any) => record.item_location === value,
    },
    {
      title: formatTitle('LOCATION ID'),
      dataIndex: 'location_id',
      key: 'location_id',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.location_id?.localeCompare(b.location_id)
    },
    {
      title: "UOM",
      dataIndex: "measurement_unit",
      key: "measurement_unit",
      ...TABLE_CELL_CONFIG,
      width: 120,
      render: initialCellValue
    },
  ]


  const onTextSearch = (text: string, dataSource?: any) => {
    let tempData = []

    if (dataSource) {
      tempData = dataSource
    } else if (filterDropdownState.value === PRODUCT_TYPE.generalProduct.value) {
      tempData = fullTableData.general
    } else if (filterDropdownState.value === PRODUCT_TYPE.eCommerceProduct.value) {
      tempData = fullTableData.ecom
    } else if (filterDropdownState.value === PRODUCT_TYPE.xplProduct.value) {
      tempData = fullTableData.distribution
    } else if (filterDropdownState.value === PRODUCT_TYPE.acgProduct.value) {
      tempData = fullTableData.acg
    }


    const filtered: any = Array.isArray(tempData) && tempData.filter((item: any) => {
      const itemName = item?.item_name?.toLowerCase() || ''
      const sku = item?.sku?.toLowerCase() || ''
      const search = text?.toLowerCase() || ''
      return itemName.includes(search) || sku.includes(search)
    })
    setFilterData(filtered)

  }


  // const handleBypassBtnClick = () => {
  //   setAddItemModal({ ...addItemModal, open: true, bypass: true })
  // }

  const renderOption = (type?: string) => {

    if (type == 'columns') {
      let opts = []

      // ['item_location', 'cost_price', 'selling_price', 'total_stock_in_hand', 'safety_stock_count', 'reorder_level', 'lead_time_for_replenishement', 'date_of_last_purchase']

      opts = [

        {
          title: formatTitle("Cost price/Unit"),
          dataIndex: "cost_price",
        },
        {
          title: formatTitle("Selling Price"),
          dataIndex: "selling_price",
        },

        {
          title: formatTitle('Lead Delivery Time'),
          dataIndex: 'lead_time_for_replenishement'
        },
        {
          title: formatTitle('Supplier'),
          dataIndex: 'supplier_name'
        },
        {
          title: formatTitle('Date Of Last Purchase'),
          dataIndex: 'date_of_last_purchase'
        }
      ]


      return getSelectorOptions(opts, '', 'dataIndex', 'title')

    }

  }

  const addItemModalOnFinish = async () => {
    closeAddItemModal()
    // get table data
    await getProductsData()
  }

  const closeAddItemModal = () => {
    setAddItemModal({ ...addItemModal, open: false, transferLocation: false, bypass: false, viewOnly: false, data: {} })
  }


  const filterMenu = () => {
    const onClick = (e: any) => {
      let label = filterDropdownState.label

      if (e.key === PRODUCT_TYPE.generalProduct.value) {
        label = PRODUCT_TYPE.generalProduct.label
        setFilterData(fullTableData.general)
      } else if (e.key === PRODUCT_TYPE.eCommerceProduct.value) {
        label = PRODUCT_TYPE.eCommerceProduct.label
        setFilterData(fullTableData.ecom)
      } else if (e.key === PRODUCT_TYPE.xplProduct.value) {
        label = PRODUCT_TYPE.xplProduct.label
        setFilterData(fullTableData.distribution)
      } else if (e.key === PRODUCT_TYPE.acgProduct.value) {
        label = PRODUCT_TYPE.acgProduct.label
        setFilterData(fullTableData.acg)
      }


      setDropdownState({
        ...filterDropdownState,
        value: e.key,
        label: label
      })
      // clear search text filters
      setSearchText('')
    }

    return (
      <Menu onClick={onClick}>
        <Menu.Item key={PRODUCT_TYPE.generalProduct.value}>
          {PRODUCT_TYPE.generalProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.eCommerceProduct.value} >
          {PRODUCT_TYPE.eCommerceProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.xplProduct.value} >
          {PRODUCT_TYPE.xplProduct.label}
        </Menu.Item>
        <Menu.Item key={PRODUCT_TYPE.acgProduct.value} >
          {PRODUCT_TYPE.acgProduct.label}
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        {/* <FlowInfoPanel
          items={[...STOCK_FLOW_STEPS]}
          style={{ minWidth: '600px', marginLeft: '15px' }}
        /> */}

      </div>
      <TableCard>
        <div style={{
          color: 'white',
          margin: '0 10px',
        }}>
          <Row gutter={[0, 15]} align="middle" justify="space-between" >
            <Col>
              <Row gutter={[15, 15]} align="bottom">
                <Col>
                  <Dropdown overlay={filterMenu} trigger={['hover', 'click']} getPopupContainer={trigger => trigger.parentNode as HTMLElement} >
                    <SimpleButton
                      text={<BtnText>{filterDropdownState.label}<DownOutlined style={{ marginLeft: '20px' }} /></BtnText>}
                    />
                  </Dropdown>
                </Col>
                <Col>
                  <Input
                    type="search"
                    placeholder="Search by Product Name or Inventory ID"
                    onSearch={() => onTextSearch(searchText)}
                    onChange={(e: any) => {
                      setSearchText(e.target.value)

                      if (!e.target.value) {

                        onTextSearch('')
                      }

                    }}
                    style={{ width: '300px' }}
                    value={searchText}
                    allowClear
                  />
                </Col>
              </Row>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'end', gap: '15px' }}>
              {/* <Row style={{ display: 'flex', alignItems: 'center' }}>
              <ColumnSelectButton
                selectedColumns={selectedTableColumns}
                columnOptions={selectColumnOptions}
                updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
              />
            </Row> */}

              {/* <SimpleButton
                title='Bypass Supplier Order'
                text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Inventory </BtnText>}
                onClick={handleBypassBtnClick}
                style={{ marginTop: '5px', padding: '0px 15px' }}
              /> */}
              {/* <SimpleButton
              text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add Equipment</BtnText>}
              onClick={handleAddEquipBtnClick}
              style={{ marginTop: '5px', padding: '0px 15px' }}
            /> */}
            </Col>
          </Row>
        </div>
        {
          !isLoading && filterData?.length === 0 ?
            <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
              Start managing your Inventory.
              <DisplayEmptyTextSecondary>
                Monitor, add and view the list of items.
                {/* Monitor your inventory levels, view real-time updates, and set alerts to ensure you never run out of stock */}
              </DisplayEmptyTextSecondary>
            </DisplayEmptyTextPrimary>
            :
            <AdiTable
              loading={isLoading}
              columns={filterDropdownState.value === PRODUCT_TYPE.acgProduct.value ? acgProductColumns : tableColumns}
              tableData={filterData}
              fullWidth
              marginTop='20px'
              scroll={{ x: '100%', y: '70vh' }}
              extraPaginationPadding
              onRowClick={filterDropdownState.value !== PRODUCT_TYPE.acgProduct.value ? (record: any) => {
                setProductModalData({ ...productModalData, value: record?.sku, productData: record })
              } : undefined}
            />
        }
      </TableCard>

      <AddInventoryItemForm
        formName={'inventory'}
        open={addItemModal.open}
        onClose={closeAddItemModal}
        reload={addItemModalOnFinish}
        bypass={addItemModal.bypass}
        transferLocation={addItemModal.transferLocation}
        inventoryData={addItemModal.data}
      />
      <ReceiveOrderBarcodeModal
        open={barcodeModal.open}
        record={barcodeModal.record}
        onClose={() => {
          setBarcodeModal({ ...barcodeModal, open: false, record: null })
        }}
      />
      {/*  row click modal*/}
      <InventoryDisplayDataModal
        type={productModalData.type}
        value={productModalData.value}
        onClose={() => setProductModalData({ ...productModalData, value: '', productData: null })}
        toggleValue={filterDropdownState.value === PRODUCT_TYPE.generalProduct.value ? 'general' : 'finished'}
        productData={productModalData.productData}
      />

    </div>
  )

}

export default AllInventory;

const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`;



