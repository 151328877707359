import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

type Props = {
  items: string[];
};

const GeneralProcessMap = (props: Props) => {
  const { items } = props;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const checkScroll = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const hasHorizontalScroll = container.scrollWidth > container.clientWidth;

      const isScrolledLeft = container.scrollLeft > 0;
      const isScrolledRight = container.scrollLeft < (container.scrollWidth - container.clientWidth - 2);

      setShowLeftArrow(isScrolledLeft);
      setShowRightArrow(hasHorizontalScroll && isScrolledRight);
    }
  };

  useEffect(() => {
    checkScroll();

    const resizeObserver = new ResizeObserver(() => {
      setTimeout(checkScroll, 100);
    });

    if (scrollContainerRef.current) {
      resizeObserver.observe(scrollContainerRef.current);
    }

    window.addEventListener('resize', checkScroll);

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScroll);
    }

    const intervalId = setInterval(checkScroll, 1000);

    return () => {
      window.removeEventListener('resize', checkScroll);
      if (container) {
        container.removeEventListener('scroll', checkScroll);
      }
      resizeObserver.disconnect();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    checkScroll();
    const timeoutId = setTimeout(checkScroll, 500);
    return () => clearTimeout(timeoutId);
  });

  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <StyledContainerWrapper>
      {showLeftArrow && (
        <ScrollArrow onClick={scrollLeft} className="left">
          <LeftOutlined />
        </ScrollArrow>
      )}

      <StyledContainer ref={scrollContainerRef}>
        <StyledInnerContainer>
          {items.map((item, index) => (
            <StyledItem key={index}>
              <StyledNumber>{index + 1}</StyledNumber>
              <StyledText>{item}</StyledText>
            </StyledItem>
          ))}
        </StyledInnerContainer>
      </StyledContainer>

      {showRightArrow && (
        <ScrollArrow onClick={scrollRight} className="right">
          <RightOutlined />
        </ScrollArrow>
      )}
    </StyledContainerWrapper>
  );
};

export default GeneralProcessMap;

const StyledContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`;

const ScrollArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 36px;
  height: 36px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  font-size: 18px;
  
  &.left {
    left: -12px;
  }
  
  &.right {
    right: -12px;
  }
  
  &:hover {
    background-color: #2a60d4;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  justify-content: start;
  border-radius: 10px;
  border: 1px solid var(--input-border-color);
  background-color: var(--panel-color);
  padding: 10px;
  overflow-x: auto;
  scrollbar-width: none; /* for Firefox */
  -ms-overflow-style: none; /* for IE y Edge */
  
  /* hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: max-content;
  width: 100%;
`;

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--white-text-color);
  gap: 10px;
  font-size: 14px;
  padding: 8px 16px;
  min-width: 160px;
  flex: 1;
  opacity: 0.9;
`;

const StyledText = styled.div`
  white-space: normal;
  line-height: 1.3;
`;

const StyledNumber = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--primary-color);
  color: var(--white-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
`;