import React, { useState, useCallback, useEffect, MouseEvent } from "react";
import SalesHeader from "containers/Pages/InventoryManagement/components/Widgets/SalesHeader";
import moment from "moment";
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary, FlexWrapper, StyledStatusLabel, TableStatusTag } from "statics/styles/StyledComponents";
import AdiTable from "components/AdiTable";
import { INCIDENT_REPORT_FLOW_STEPS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";
import { capitaliseFirst, formatNameString, formatTitle, initialCellValue, initialDatellValue, initialEnumCellValue, initialPriceCellValue } from "utilities/Functions/FormatFunctions";


import GenerateIncidentReport from "../Sales/GenerateIncidentReport";
import { getSelectorOptions } from "utilities/Functions/GlobalHelperFunctions";
import { checkWindowSelectedText } from "containers/Pages/InventoryManagement/utilities/functions";
import IncidentReportAPI from "containers/Pages/InventoryManagement/apis/IncidentReportAPI";
import FlowInfoPanel from "components/FlowInfoPanel";
import TableCard from "components/AdiTable/TableCard";
import EmptyDisplay from "components/AdiTable/EmptyDisplay";
import SimpleButton from "components/GlobalButtons/SimpleButton";
import { ProcessMap } from "components/ProcessMap";

type Props = {
  active: boolean;
};

const ReportsTableView = (props: Props) => {
  const { active } = props;

  const [data, setData] = useState<any>([])
  const [filterData, setFilterData] = useState<any[]>([])
  const [filters, setFilters] = useState<any>({
    searchText: '',
    dateRange: ['', ''],
  })
  const [incidentItemData, setIncidentItemData] = useState<any>();
  const [incidentModalOpen, setIncidentModalOpen] = useState<boolean>(false);
  const [incidentViewMode, setIncidentViewMode] = useState<boolean>(false);
  const [currentTableView, setCurrentTableView] = useState<string>('Incident Report');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (active) {
      // initialisation
      if (currentTableView === 'Incident Report') {
        getTableData()
      }
    }
  }, [active, currentTableView]);

  const getTableData = () => {
    setIsLoading(true)
    IncidentReportAPI.getIncidentReportData().then(result => {
      console.log('getTableData report success...', result)
      Array.isArray(result) && result.forEach((row: any, index: number) => {
        row.key = index + 1
      })
      if (Array.isArray(result)) {
        // add key to each row, return new result
        const newResult = result.map((item: any, index: number) => {
          return {
            ...item,
            key: index + 1
          }
        })
        setData(newResult)
        // setFilterData(newResult)
        applyAllFilters(filters.searchText, filters.dateRange, newResult)
      }

    }).catch(err => {
      console.log('getTableData report fail...', err)

    }).finally(() => {
      setIsLoading(false)
    })


  }


  const applyAllFilters = (searchText: string, dateRange: [string, string], dataSource?: any) => {
    const tempData = dataSource || data
    let result = onTextSearch(searchText, tempData)
    result = handleFilterByDate(dateRange, result)
    setFilterData(result)
  }


  const onTextSearch = (searchText: string, dataSource?: any) => {
    const tempData = dataSource || data
    if (searchText === '') {
      return tempData
    }
    let filtered: any[] = [];
    filtered = tempData.filter((item: any) => {
      const itmNo = item?.incident_ID.toLowerCase() || ''
      const search = searchText?.toLowerCase() || ''
      return itmNo.includes(search)
    })

    // setFilterData(filtered)
    return filtered

  }

  const handleFilterByDate = (date: [string, string], dataSource?: any) => {
    const tempData = dataSource || data

    if (date[0] === '' && date[1] === '') {
      return tempData
    }
    const startDate: string = date[0] != "" ? moment(date[0], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    const endDate: string = date[1] != "" ? moment(date[1], 'DD/MM/YYYY').format('YYYY-MM-DD') : ''
    let filtered: any[] = [];
    if (startDate != '' && endDate != '') {
      filtered = tempData.filter((item: any) => {
        const reportedDate: string = moment(item?.reported_on).format('YYYY-MM-DD') || ''
        // compare dates
        return reportedDate >= startDate && reportedDate <= endDate
      })
      // setFilterData(filtered)
      return filtered
    } else {
      // setFilterData(tempData)
      return tempData
    }
  }

  const getOrderStatusTextColor = (status: string) => {
    switch (status) {
      case 'Disposed':
        return '#E57878'
      case 'Reused':
        return '#77B900'
      default:
        return '#77B900'
    }
  }

  const renderIncidentStatusLabel = (text: string) => {
    let displayStatus: string = ''
    if (typeof text === 'object') {
      const key = Object.keys(text)
      if (key[0] != '') {
        displayStatus = capitaliseFirst(key[0])
      }
    }

    return (
      <FlexWrapper>
        <TableStatusTag color={getOrderStatusTextColor(displayStatus)} style={{ width: '120px', display: 'block' }}>
          {displayStatus == 'Reused' ? 'Saved for Re-use' : 'Disposed'}
        </TableStatusTag>
      </FlexWrapper>
    )

  }

  const columnWidth240 = 240

  const IncidentColumns = [
    {
      title: formatTitle("INCIDENT REPORT NO."),
      dataIndex: "incident_ID",
      key: "incident_ID",
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
    },
    // {
    //   title: formatTitle("PRODUCT STATUS"),
    //   dataIndex: "product_status",
    //   key: "product_status",
    //   ...TABLE_CELL_CONFIG,
    //   width: columnWidth240,
    //   align: 'center',
    //   render: (text: string) => renderIncidentStatusLabel(text),
    // },
    {
      title: formatTitle('Internal Lot/Batch No.'),
      dataIndex: 'processing_batch_number',
      key: 'processing_batch_number',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,

    },
    {
      title: formatTitle('REPORTED ON'),
      dataIndex: 'reported_on',
      key: 'reported_on',
      ...TABLE_CELL_CONFIG,
      render: (text: string) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text),
    },
    {
      title: formatTitle('REPORTED BY'),
      dataIndex: 'reported_by',
      key: 'reported_by',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: (text: string) => text ? text : initialCellValue,
    },
    {
      title: formatTitle('PRODUCT'),
      dataIndex: 'item_name',
      key: 'item_name',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      render: initialCellValue,
    },
    {
      title: formatTitle('QUANTITY'),
      dataIndex: 'quantity_affected',
      key: 'quantity_affected',
      ...TABLE_CELL_CONFIG,
      width: columnWidth240,
      // render: initialCellValue,
      render: (text: any, record: any, index: number) => text ? `${text} ${record.measurement_unit}` : initialCellValue(text),

    },
    // {
    //   title: formatTitle('Comments'),
    //   dataIndex: 'comments',
    //   key: 'comments',
    //   ...TABLE_CELL_CONFIG,
    //   width: columnWidth240,
    //   render: (text: string) => text ? capitaliseFirst(text) : initialCellValue,
    // },


  ]

  const closeModal = () => {
    setIncidentModalOpen(false)
  }

  const handleIncidentModalOpen = () => {
    setIncidentModalOpen(true)

  }

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ margin: '10px 0px' }}>
        {/* <FlowInfoPanel
          items={[...INCIDENT_REPORT_FLOW_STEPS]}
          style={{ minWidth: '700px', marginLeft: '35px' }}
        /> */}
        <ProcessMap
          items={[...INCIDENT_REPORT_FLOW_STEPS.map((item: any) => item.label)]} />
      </div>

      {
        currentTableView !== 'Incident Report' || (!isLoading && filterData?.length === 0) ?
          <EmptyDisplay data={{
            title: 'Start managing your Reports.', text: 'Create and manage your Reports',
            buttons: [<SimpleButton
              text='+ Incident Report'
              onClick={handleIncidentModalOpen}
            />],
          }} />
          :
          <TableCard>
            <SalesHeader
              onTextSearch={(text: string) => {
                setFilters({ ...filters, searchText: text })
                // onTextSearch(text)
                applyAllFilters(text, filters.dateRange)
              }}
              onDateChange={(date: [string, string]) => {
                setFilters({ ...filters, dateRange: date })
                // handleFilterByDate(date)
                applyAllFilters(filters.searchText, date)
              }}
              defaultValue={currentTableView}
              pageView={'reports'}
              searchPlaceholder="Search by Report No."
              selectPlaceholder="Select Report"
              handleIncidentModalOpen={handleIncidentModalOpen}
            />
            <AdiTable
              loading={isLoading}
              scroll={{ x: '100%', y: '50vh' }}
              columns={IncidentColumns}
              tableData={filterData}
              rowKey={(record: any) => record.order_ID}
              fullWidth
              marginTop='20px'
              onRowClick={(record: any, rowIndex: any, e: any) => {
                e.stopPropagation()
                e.preventDefault()
                if (checkWindowSelectedText()) return
                setIncidentItemData(record)
                setIncidentModalOpen(true)
                setIncidentViewMode(true)
              }}
              extraPaginationPadding
            />
          </TableCard>
      }


      {incidentModalOpen ? (
        <GenerateIncidentReport
          incidentItemData={incidentItemData}
          setIncidentItemData={setIncidentItemData}
          open={incidentModalOpen}
          onCancel={closeModal}
          // getTableData={getTableData}
          incidentViewMode={incidentViewMode}
          setIncidentViewMode={setIncidentViewMode}
          initializeAll={() => {
            getTableData()
          }}
        />
      ) : null}
    </div>
  )
};

export default ReportsTableView;
