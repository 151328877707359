import React, { useEffect, useState } from 'react';
import AdiTable from 'components/AdiTable'
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, message } from 'antd';
import styled from "styled-components";


import moment from "moment";
import QRModal from '../../../../components/QRModal';
import AddGS1KeyAPI from '../apis/Gs1Key';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import { ActionIcon } from 'components/AdiTable/ActionDropdown';

interface KeyListProps {
  getDataByCustomer: () => void;
  productSelected: any;
  onDataLoad: (data: any[]) => void;
  selectedFilter: string;
  onFilterChange: (filter: string) => void;
  onKeyStatusChange: () => void;
  refreshTrigger: number;
}

interface KeyData {
  payment_id: string;
  payment_method: string;
  product_name: string;
  created_date: string;
  status: string;
  qr_code: string;
  key: string;
  product_id: string;
  update_on?: string;
}

const KeyList: React.FC<KeyListProps> = ({
  getDataByCustomer,
  productSelected,
  onDataLoad,
  selectedFilter,
  onFilterChange,
  onKeyStatusChange,
  refreshTrigger
}) => {
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);
  console.log('productSelected', productSelected)

  const [isQRModalOpen, setIsQRModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allKeys, setAllKeys] = useState<KeyData[]>([]);
  const [filteredKeys, setFilteredKeys] = useState<KeyData[]>([]);

  const [modalData, setModalData] = useState();
  const getDataByProductId = () => {
    setIsLoading(true)
    AddGS1KeyAPI.getKeyByProductId(productSelected.product_id).then((response) => {
      console.log(response)
      setAllKeys(response)
      filterData(response, selectedFilter)
    }).catch((err: any) => {
      console.log('err', err)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const fetchKeyListData = async () => {
    try {
      setIsLoading(true);
      const response = await AddGS1KeyAPI.getKeyByProductId(productSelected.product_id);
      if (response) {
        setAllKeys(response);
        filterData(response, 'Total Keys');
        onDataLoad(response);
      }
    } catch (error) {
      console.error('Error fetching key list data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterData = (data: KeyData[], filter: string) => {
    switch (filter) {
      case 'Active Keys':
        setFilteredKeys(data.filter(item => item.status === 'active'));
        break;
      case 'Inactive Keys':
        setFilteredKeys(data.filter(item => item.status === 'inactive'));
        break;
      case 'Total Keys':
      default:
        setFilteredKeys(data);
        break;
    }
  };

  useEffect(() => {
    if (allKeys.length > 0) {
      filterData(allKeys, selectedFilter || 'Total Keys');
    }
  }, [selectedFilter, allKeys]);

  useEffect(() => {
    if (productSelected?.product_id) {
      fetchKeyListData();
    }
  }, [productSelected, refreshTrigger]);

  const actionColumn = isAdminModuleReadOnly ? [] : [
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (text: any, record: any) => (
        <>
          <Dropdown getPopupContainer={trigger => trigger.parentNode as HTMLElement} overlay={renderMenu(record)}>
            <ActionIcon onClick={(e) => e.stopPropagation()} />
          </Dropdown>
        </>
      ),
    }
  ]

  const columns = [
    {
      title: 'KEY ID',
      dataIndex: 'key',
      align: 'left',

    },

    {
      title: 'ACTIVATED ON',
      dataIndex: 'created_date',
      align: 'left',
      render: (text: any, record: any, index: number) => (
        <p>{moment(record.createdAt).format('DD/MM/YYYY')}</p>
      )
    },
    {
      title: 'PRODUCT NAME',
      dataIndex: 'product_name',
      align: 'left',
    },
    {
      title: 'PRODUCT ID',
      dataIndex: 'product_id',
      align: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      render: (text: any, record: any) => (
        <p>{record.status == 'active' ? 'Active' : 'Deactive'}</p>
      )
    },
    ...actionColumn
  ]
  const updateKeyData = async (data: any) => {
    const payload = {
      key: data.key,
      status: data.status === 'active' ? 'inactive' : 'active',
      updated_on: moment().toISOString()
    };

    try {
      await AddGS1KeyAPI.updateKeyByCustomer([payload]);
      message.success('Key updated successfully!');
      onKeyStatusChange();
      await fetchKeyListData();
    } catch (error) {
      console.error('Error updating key:', error);
      message.error('Something went wrong');
    }
  }
  const renderMenu = (data: any) => {
    const handleMenuClick = (e: any) => {
      e.domEvent.stopPropagation()
      console.log(e.key)
      if (e.key == 'view') {
        console.log('okineidt')
        setIsQRModalOpen(true);
        setModalData(data)
      } else if (e.key == 'active') {
        console.log('acative')
        updateKeyData(data)
      } else if (e.key == 'deactive') {
        updateKeyData(data)
        console.log('deactive')
      }
    }

    return (
      <Menu className='dropdownMenu' onClick={handleMenuClick}>

        {data.status == 'active' ? (
          <>
            <Menu.Item key="view">
              View
            </Menu.Item>
            <Menu.Item key="deactive">
              Deactivate
            </Menu.Item></>
        ) :
          (
            <Menu.Item key="active">
              Activate
            </Menu.Item>)}
      </Menu >
    )
  }

  return (
    <div style={{ marginTop: 15 }}>
      <AdiTable
        loading={isLoading}
        columns={columns}
        tableData={filteredKeys}
        fullWidth
        marginTop='20px'
      />

      <QRModal
        open={isQRModalOpen}
        setIsQRModalOpen={setIsQRModalOpen}
        modalData={modalData}
      />

    </div>
  );
};

const MoreIcon = styled(MoreOutlined)`
  position: absolute;
  // right: 10px;
  top: 13px;
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
  &:hover {
    cursor: pointer;
    background-color: #33334d;
    filter: brightness(1.2);
    border-radius:50%;
  }
`

export default KeyList;