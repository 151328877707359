import Input from "components/Input";
import React, { useState, useEffect, useCallback, ChangeEvent } from "react";
import { ADIKeyPage, DataRow, FlexBox, FlexWrapper, GridFive, LeftDesc, RightDesc, StyledButton, StyledText, TrackGridThree, TrackGridTwo, primaryRed, TrackGridFour, FlexBoxVertical, ToggleDiv, Toggle, DisplayEmptyTextPrimary, DisplaySecondaryText } from "statics/styles/StyledComponents";
import styled from "styled-components";
import { MAINTENANCE_DATA } from 'statics/Asset Data'
import { Button, Col, Collapse, Form, message, Row, Space, Upload } from "antd";

import { convertDateAndTime, formatDateString, getBizCode, getCustomAttributeObj, getFilterSearchUrl, getSelectorOptions, getUploadToS3Promise } from "utilities/Functions/GlobalHelperFunctions";
import { ASSET_MAINTENANCE_TYPES } from "utilities/CONSTANTS";
import STATIC_IMAGES from "statics/images";
import Statistics from "components/Statistics";
import MaintenanceType from "./MaintenanceType";
import { ASSET_MANAGEMENT_API } from "utilities/AdiApi";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { capitaliseFirst, formatNameString } from "utilities/Functions/FormatFunctions";
import { UploadFileTypes } from "utilities/types";
import DisplayStats from "components/Statistics/DisplayStats";


import MaintenanceAPI from "../../apis/MaintenanceAPI";
import DropdownAPI from "../../apis/DropdownAPI";
import SummaryAPI from "../../apis/SummaryAPI";
import TableCard from "components/AdiTable/TableCard";
import DueIcon from '../../../../../assets/analytics/asset-management/due.svg';
import CompletedIcon from '../../../../../assets/analytics/asset-management/completed.svg';
import { Analytics } from 'components/Analytics';

type Props = {};

export type MAINTENANCE_TYPES = 'due' | 'completed'

export const TABS: { [key: string]: MAINTENANCE_TYPES } = {
    // UPCOMING: "UPCOMING",
    DUE: "due",
    // OVERDUE: "Due",
    COMPLETED: "completed",

};
const MODAL_TABS = {
    LOGS: "Maintenance Logs",
    SCHEDULE: "View Schedule",

};

interface MaintenanceDataType {
    asset_ID: string;
    type: string;

    datetime: string;

    asset_master_name: string;
    maintenance_name: string;
    maintenance_comments: string;

    // for customer attributes
    // for form filling purpose
    // any could be a string or a file object
    [key: string]: any;

    // for hold the attributes input value
    attributes_to_collect?: {
        bool: string[],
        datetime: string[],
        doc: string[],
        int: string[],
        str: string[],
    }

    // for hold the attributes types
    custom_attributes?: {
        [key: string]: any;
    }


    completion_comments: string;
    completion_date: string;
    status: string;
    frequency: string;

}

const Maintenance = (props: Props) => {
    const BIZ_CODE = getBizCode();

    const [form] = Form.useForm();

    const emptyFilter = {
        search: '',
        asset_master_name: '',
        maintenance_name: '',
        start_date: null, // date will be null or Moment type
        end_date: null, // date will be null or Moment type

    }

    const [filters, setFilters] = useState<{ [key: string]: string | null | Moment }>(emptyFilter);

    const [pmRuleList, setPmRuleList] = useState<any[]>([]);
    const [assetPresetList, setAssetPresetList] = useState<any[]>([])

    const [tab, setTab] = useState<MAINTENANCE_TYPES>(TABS.DUE);

    const [modalTab, setModalTab] = useState<string>(MODAL_TABS.LOGS);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [tableData, setTableData] = useState<any>([]);

    const emptyData = {
        asset_ID: '',
        type: 'MAINTANENCE',
        datetime: '',

        asset_master_name: '',
        maintenance_name: '',
        maintenance_comments: '',

        // for customer attributes
        // for form filling purpose
        // [key: string]: any;

        // for hold the attributes input value
        attributes_to_collect: {
            bool: [],
            datetime: [],
            doc: [],
            int: [],
            str: [],
        },

        // // for hold the attributes types
        custom_attributes: {},


        completion_comments: '',
        completion_date: '',
        status: '',
        frequency: '',
    }

    // selectedData will hold table row record and files for the attributes
    const [selectedData, setSelectedData] = useState<MaintenanceDataType>(emptyData);


    // just to keep the record clicked when row is clicked
    // const [curRecord, setCurRecord] = useState<MaintenanceDataType>(emptyData);
    const [oneAssetMaitenanceData, setOneAssetMaitenanceData] = useState<MaintenanceDataType[]>([]);


    const [cardsData, setCardsData] = useState(
        Object.keys(ASSET_MAINTENANCE_TYPES).map((item: string, index: number) => ({
            // bgOne: ASSET_MAINTENANCE_BACKGROUNDS_SETS[ASSET_MAINTENANCE_TYPES[item]].bgOne,
            // bgTwo: ASSET_MAINTENANCE_BACKGROUNDS_SETS[ASSET_MAINTENANCE_TYPES[item]].bgTwo,
            // icons: STATIC_IMAGES.ASSET_MAINTENANCE_ICONS[ASSET_MAINTENANCE_TYPES[item]],
            text: capitaliseFirst(item),
            number: 0,
        }))
    );

    const tabsData = Object.keys(TABS).map((k: string) => {
        // let curLabel = ''
        // if (k == TABS.COMPLETED) {
        //     curLabel = 'COMPLETED'
        // } else {
        //     curLabel = k
        // }
        return ({
            key: TABS[k], label: formatNameString(TABS[k]), minWidth: ""
        })
    })

    const getValueByTitle = (title: string) => {
        const item = cardsData?.find((item: any) => item.text === title);
        return item ? item.number : 0;
    };

    const analyticsInfo = [
        {
            icon: <img src={DueIcon} alt="AllGridIcon" />,
            title: 'Due',
            value: getValueByTitle('Due'),
            onClick: () => setTab(TABS.DUE),
        },
        {
            icon: <img src={CompletedIcon} alt="AllGridIcon" />,
            title: 'Completed',
            value: getValueByTitle('Completed'),
            onClick: () => setTab(TABS.COMPLETED),
        },
    ];


    const getSummary = async () => {
        try {
            const result = await SummaryAPI.getSummary('maintenance')
            const maintenance = result

            const newData = Object.keys(ASSET_MAINTENANCE_TYPES).map((item: string, index: number) => ({
                text: capitaliseFirst(item),
                number: maintenance[ASSET_MAINTENANCE_TYPES[item]],
            }))

            setCardsData([...newData])
        } catch (err) {
            console.log('getSummary e', err)

        }


    };

    const getTableData = useCallback(
        async (searchUrl?: string) => {
            let url = ``
            if (searchUrl) {
                url = `${BIZ_CODE}/asset_docs${searchUrl}&status=${tab}`
            } else {
                url = `${BIZ_CODE}/asset_docs?status=${tab}`
            }
            // dispatch(actions.setLoading(true))
            setIsLoading(true)

            try {
                const result = await MaintenanceAPI.getMaintenanceByAnyUrl(url)
                setTableData(result)
            } catch (err) {
                console.log('getTableData e', err)

            } finally {
                // dispatch(actions.setLoading(false))
                setIsLoading(false)
            }

        },
        [tab],
    )

    const getDropdowns = useCallback(
        async () => {
            try {
                const payload = {
                    "maintenance_rules": true,
                    "asset_presets": true,
                    //   "request_types": true,
                }
                const result = await DropdownAPI.getDropdowns(payload)

                const { maintenance_rules, asset_presets } = result

                setAssetPresetList(asset_presets)
                setPmRuleList(maintenance_rules)

            } catch (error) {
                console.log('get dropdown error maintenance', error)

            }


        },
        [],
    )


    const initialize = useCallback(
        () => {

            getTableData()
            getSummary()
            getDropdowns()
        },
        [tab],
    )
    useEffect(initialize, [tab])



    const handleModalClose = (e?: MouseEvent,) => {
        e && e.preventDefault()

        setSelectedData(emptyData)
        form.resetFields()
        form.setFieldsValue(emptyData)

        setOneAssetMaitenanceData([])

        setModalTab(MODAL_TABS.LOGS)
        // setIsEdit(false)

    };

    const handleDateChange = (
        date: any,
        datestring: string,
        fieldName: string
    ) => {
        console.log("dateTime", date, datestring, fieldName, datestring[0], datestring[1]);

        const latestFilters = {
            ...filters,
            start_date: date && date[0] ? date[0] : null,
            end_date: date && date[1] ? date[1] : null,
        }

        // set filters for search
        const formatedFilters = {
            ...filters,
            start_date: date && date[0] ? convertDateAndTime(date[0].hour(0)) : "",
            // end_date: date && date[0] ? convertDateAndTime(end_date_search) : "",
            end_date: date && date[1] ? convertDateAndTime(date[1].hour(23)) : "",
        }

        console.log('latestFilters', formatedFilters)
        setFilters(latestFilters)

        if (datestring.length > 0) {
            handleSearch(formatedFilters)
        }
    };

    const onSelectType = (e: string, selectorName: string) => {
        // console.log("onSelectLevel", e, selectorName);
        const selectedItem = e;
        const latestFilters = {
            ...filters,
            [selectorName]: selectedItem
        }
        console.log("onSelectLevel latestFilters", latestFilters);
        setFilters(latestFilters)
        // console.log("onSelectLevel", e, selectorName);

        if (selectorName !== 'search') {
            handleSearch(latestFilters)
        }



    };

    const handleSearch = (latestFilters?: any) => {
        console.log('handleSearch', filters)
        let curFilters: any

        // for selector search
        if (latestFilters) {
            curFilters = { ...latestFilters }
        }
        // for input enter search
        else {
            curFilters = { ...filters }
        }

        const searchUrl = getFilterSearchUrl(curFilters)

        if (searchUrl !== '') {
            getTableData(searchUrl)

        } else {
            getTableData()

        }


    }


    const renderHeaderTab = () => {
        return (
            <ToggleDiv style={{ width: '200px', marginBottom: '20px' }}>
                {tabsData.map((item: any) => {
                    return (
                        <>
                            <Toggle border={tab === item.key ? true : false}
                                onClick={() => {
                                    // if (handleToggle) handleToggle('All')
                                    // setToggle('All')
                                    setTab(item.key);
                                    handleModalClose()
                                    setFilters(emptyFilter)

                                }}
                            >
                                {item.label}
                            </Toggle>

                        </>

                    );
                })}

            </ToggleDiv>
        )
    }

    const renderHeader = () => {
        return (
            <Row style={
                {
                    padding: '0 10px',
                }
            }>
                <Col style={{ marginRight: '24px' }}>
                    {renderHeaderTab()}
                </Col>
                <Col>
                    <TrackGridFour>
                        <Input
                            placeholder="Search by Asset Name or ID "
                            name="search"
                            type="search"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectType(e.target.value, 'search')}
                            value={filters.search}
                            onSearch={() => handleSearch()}
                            allowClear
                        />
                        <Input
                            placeholder="Maintenance Rule"
                            name="maintenance_name"
                            type="select"
                            options={renderOption('pm_rules')}
                            onChange={(value: string) => onSelectType(value, 'maintenance_name')}
                            value={filters.maintenance_name}
                        />
                        <Input
                            placeholder="Date range"
                            name=""
                            type="daterange"
                            onChange={(date: any, datestring: string) => {
                                handleDateChange(date, datestring, "Selected Date")
                            }}
                            disabledDate={() => false}
                            value={[filters.start_date, filters.end_date]}
                        />
                    </TrackGridFour>
                </Col>
            </Row>

        );
    };

    const renderTabPages = (): JSX.Element => {
        console.log("tab", tab);
        return (
            <MaintenanceType
                type={tab}
                isLoading={isLoading}
                // handleModalOpen={handleModalOpen}
                // handleModalClose={handleModalClose}
                tableData={tableData}
                // getTableData={getTableData}
                initializeAll={initialize}
            />
        )
    };


    const renderOption = (type?: string) => {

        if (type == 'asset_presets') {
            return getSelectorOptions(assetPresetList, 'Select Asset Preset', '', 'preset_name')

        } else if (type == 'pm_rules') {
            const formatedList: any[] = []
            Array.isArray(pmRuleList) && pmRuleList.length > 0 && pmRuleList.map((item: any) => {
                formatedList.push({
                    value: item.rule_name,
                    label: `${item.rule_name} - ${item.preset_name}`
                })
            })
            return getSelectorOptions(formatedList, 'Select Maintenance Rule', 'value', 'label')

        }

        return getSelectorOptions([], 'Select')

    }



    return (
        <div>

            {/* <div style={{ marginBottom: '20px' }}>
                <DisplayStats data={cardsData} />
            </div> */}
            <div style={{ marginBottom: '20px' }}>
                <Analytics items={analyticsInfo} />
            </div>
            {!isLoading && Array.isArray(tableData) && tableData.length == 0 ? (
                <>
                    <MainDiv >
                        {renderHeader()}
                    </MainDiv>
                    <DisplayEmptyTextPrimary style={{ marginTop: '200px' }}>
                        No Maintenance schedules found for your Assets
                        <DisplaySecondaryText>
                            Please create and manage Asset maintenance schedules from Master Data &gt; PM Preset
                        </DisplaySecondaryText>
                    </DisplayEmptyTextPrimary>
                </>
            ) : (
                <TableCard>
                    {renderHeader()}
                    {renderTabPages()}
                </TableCard>
            )}





        </div>
    );
};

export default Maintenance;

const MainDiv = styled.div`
//   width: 68%;
  margin-top: 20px;
//   padding: 3rem;
//   background-color: #383838;
//   border-radius: 4px;
//   height: 70vh;
//   overflow-y: scroll;
`;
