import { Col, Row, message } from 'antd'
import FlowInfoPanel from 'components/FlowInfoPanel'
import React, { useState, MouseEvent, useEffect, useCallback, useMemo } from 'react'
import { SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG, USER_MANAGEMENT_FLOW_STEPS, USER_MANAGEMENT_FLOW_STEPS_UPDATED } from 'utilities/CONSTANTS'
import ADI_Input from 'components/Input';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import { ActionButton, BtnText } from 'statics/styles/StyledComponents';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import AdiTable from 'components/AdiTable';
import { formatTitle, initialCellValue, initialDateTimeValue } from 'utilities/Functions/FormatFunctions';
import { ADI_CONFIRM, getBizCode, getSessionStorage } from 'utilities/Functions/GlobalHelperFunctions';
import axios from 'axios';
import UserCreate, { useUserCreateDrawer } from './UserCreate';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import { ProcessMap } from './../../../../components/ProcessMap';


import useGeneralStore, { GeneralStore } from 'zustand-stores/useGeneralStore';
import TableCard from 'components/AdiTable/TableCard';

const BIZ_CODE = getBizCode();

console.log('get BIZ_CODE', BIZ_CODE)

const userAPIs = {
  createUser: {
    url: `${process.env.REACT_APP_API_BASE}itrazo/user/create`,
  },
  updateUser: {
    url: `${process.env.REACT_APP_API_BASE}itrazo/user/update`,
  },
  deleteUser: {
    url: `${process.env.REACT_APP_API_BASE}itrazo/user/delete`,
  },
}

const UserManagement = () => {
  const isAdminModuleReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.admin.isReadOnly);

  const BIZ_CODE = getBizCode()
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [filteredUser, setFilteredUser] = useState(
    [{
      'first_name': '',
      'last_name': '',
      'username': '',
      'email': '',
      'phone_number': '',
      'created_on': ''
    },

    ]
  );

  const drawerHandler = useUserCreateDrawer()

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)


  const getUsers = () => {
    setIsLoading(true);
    // first time not getting the biz code
    // so i kept it in the update function

    const listingUsers = `${process.env.REACT_APP_API_BASE}itrazo/user/list?customer=${getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE)}`;

    axios
      .get(listingUsers)
      .then((response: any) => {
        console.log('rrr', response)
        const { data } = response;
        if (data) {
          const Res = data.body
          if (Res) {
            const filterRole = Res.filter((role: any) => role?.role !== 'crm_customer')
            setUsersList(filterRole);
            setFilteredUser(filterRole);
          }
        }
      })
      .catch((e) => {
        console.log(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  // initialization
  useEffect(() => {
    getUsers();
  }, []);


  const onSearch = (search?: string) => {
    const text = search === undefined ? searchText.trim() : search;

    if (text && text !== "" && usersList.length) {
      const userFiltered = usersList.filter((rec: any) => {
        return (
          rec.full_name.includes(text) || rec.username.includes(text)
        );
      });
      if (userFiltered.length) {
        setFilteredUser(userFiltered);
      } else {
        setFilteredUser([]);
      }
    } else {
      setFilteredUser(usersList);
    }
  }



  const handleDelete = (record: any) => {
    console.log('Delete', record, userAPIs.deleteUser.url, { ...record, role: undefined, client_code: BIZ_CODE })
    const payload = { ...record, role: undefined, client_code: BIZ_CODE }
    setIsGlobalLoading(true)
    axios
      .post(userAPIs.deleteUser.url, payload)
      .then((response: any) => {
        console.log(response);
        message.success('User deleted successfully');
        getUsers();
      })
      .catch(e => {
        console.log(e)
        message.error('Failed to delete user')
      })
      .finally(() => {
        setIsGlobalLoading(false)
      })
  }

  const searchUser = () => {
    onSearch();
  };

  const actionColumn = isAdminModuleReadOnly ? [] : [
    //   {
    //   title: 'ACTIONS',
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   ...TABLE_CELL_CONFIG,
    //   width: 130,
    //   render: (text: any, record: any) => (
    //     <>
    //       <p style={{ marginBottom: "0" }}>
    //         <ActionButton
    //           title="Edit"
    //           onClick={(e: MouseEvent) => {
    //             e.stopPropagation()
    //             drawerHandler.openDrawerEdit(record)
    //           }}
    //         >
    //           <EditOutlined />
    //         </ActionButton>
    //         <ActionButton
    //           title="Delete"
    //           onClick={(e: MouseEvent) => {
    //             e.stopPropagation()
    //             ADI_CONFIRM({
    //               customizeTitle: 'Are you sure?',
    //               isdelete: true,
    //               onConfirm: () => {
    //                 console.log('Delete')
    //                 handleDelete(record)
    //               }
    //             })

    //           }}
    //         >
    //           <DeleteOutlined />
    //         </ActionButton>
    //       </p>
    //     </>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: "Edit",
            icon: <EditOutlined />,
            onClick: () => {
              drawerHandler.openDrawerEdit(record)
            },
          },
          {
            label: "Delete",
            icon: <DeleteOutlined />,
            onClick: () => {
              ADI_CONFIRM({
                customizeTitle: 'Are you sure?',
                isdelete: true,
                onConfirm: () => {
                  console.log('Delete')
                  handleDelete(record)
                }
              })
            },
          }
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ]

  const userManagementColumns = [
    ...userColumns,
    ...actionColumn
  ];

  console.log('filteredUser', filteredUser)

  return (
    <>
      {/* <FlowInfoPanel
        items={[...USER_MANAGEMENT_FLOW_STEPS]}
        style={{ minWidth: '800px', marginLeft: '16px' }}
      /> */}

      <ProcessMap
        items={[...USER_MANAGEMENT_FLOW_STEPS_UPDATED]} />
      <TableCard>
        <Row gutter={[0, 15]} align="middle" justify='space-between' style={{
          margin: '0 10px'
        }}>
          <Col style={{ marginLeft: "0px" }}>
            <ADI_Input
              type="search"
              placeholder="Search by User Name or ID"
              onSearch={() => searchUser()}
              onChange={(e: any) => {
                const value = e.target.value
                setSearchText(value)
                if (!value) {
                  onSearch('')
                }

              }}
              style={{ width: '250px' }}
              value={searchText}
              allowClear
            />
          </Col>
          {
            !isAdminModuleReadOnly &&
            <Col style={{ marginRight: "2px" }}>
              <SimpleButton
                text={<BtnText><PlusOutlined />&nbsp;&nbsp;Add User</BtnText>}
                onClick={drawerHandler.openDrawerAdd}
                key="create-user-button"
                style={{ marginTop: '5px', padding: '0px 15px' }}
              />
            </Col>
          }


        </Row>

        <AdiTable
          loading={isLoading}
          columns={userManagementColumns}
          tableData={filteredUser}
          fullWidth
          marginTop='20px'
          onRowClick={(record: any) => {
            drawerHandler.openDrawerView(record)
          }}
          extraPaginationPadding
        />
      </TableCard>

      <UserCreate
        handler={drawerHandler}
        createUserApi={userAPIs.createUser as any}
        amendUserApi={userAPIs.updateUser as any}
        onUpdate={getUsers}
      />

    </>
  )
}

export default UserManagement

const userColumns = [
  {
    title: formatTitle('Full Name'),
    dataIndex: 'full_name',
    key: 'full_name',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle('User Name'),
    dataIndex: 'username',
    key: 'username',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle('Email ID'),
    dataIndex: 'email',
    key: 'email',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle('Phone Number'),
    dataIndex: 'phone_number',
    key: 'phone_number',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialCellValue,
  },
  {
    title: formatTitle('Created On'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    ...TABLE_CELL_CONFIG,
    width: 200,
    render: initialDateTimeValue,
  }
]