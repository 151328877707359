import { MoreOutlined } from '@ant-design/icons';
import { message, Dropdown, Menu, } from 'antd';
import AdiTable from 'components/AdiTable';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import moment from 'moment';
import React, { ChangeEvent, useCallback, useEffect, useState, MouseEvent } from 'react'
import { DATE_TIME_FORMAT, FIXED_TABLE_HEIGHT } from 'utilities/CONSTANTS';
import { ADI_CONFIRM, getFilterSearchUrl, getSelectorOptions } from 'utilities/Functions/GlobalHelperFunctions';
import {
    FlexWrapper, TrackGridTwo,
} from "../../../../../statics/styles/StyledComponents";
import { formatNameString, formatTitle, initialCellValue, initialEnumCellValue } from 'utilities/Functions/FormatFunctions';
import { TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import DisplayStats from 'components/Statistics/DisplayStats';

import { ALLOCATION_REQUEST_TYPES, ALLOCATION_REQUEST_STATUS } from '../../statics/constants';
import AllocationRequestAPI from '../../apis/AllocationRequestAPI';
import SummaryAPI from '../../apis/SummaryAPI';
import RequestModal from './RequestModal';
import AllocationModal from './AllocationModal';
import { useAuthStore } from 'zustand-stores';
import { AuthStore } from 'zustand-stores/useAuthStore';
import CustomerStoreAPI from '../../apis/CustomerStoreAPI';
import DropdownAPI from '../../apis/DropdownAPI';
import { useResponsive } from 'utilities/hooks';
import TableCard from 'components/AdiTable/TableCard';
import { ActionIcon } from 'components/AdiTable/ActionDropdown';
import ActiveAssetIcon from '../../../../../assets/active_assets.svg';
import TotalRequestsIcon from '../../../../../assets/analytics/asset-management/total_requests.svg';
import NewOrdersIcon from '../../../../../assets/analytics/asset-management/new_orders.svg';
import NewRetrievalsIcon from '../../../../../assets/analytics/asset-management/new_retrievals.svg';
import CompletedIcon from '../../../../../assets/analytics/asset-management/completed.svg';
import { Analytics } from 'components/Analytics';

type Props = {

}

type AllocationData = {
    master_data_refID: string;
    datetime: string;
    expected_return_date: string;
    asset_IDs: string[];
    assets: { [key: string]: string }[];
    dispatch_date: string;
    dispatch_comments: string;

    csv_s3url: string;

}

type RequestData = {
    master_data_refID: string;
    request_order_number?: string;
    datetime: string;
    asset_IDs: string[];
    storeList: string[];
    type: string,
    quantity: string,
    customer_ID: string,
    customer: string,
    store: string,
    expected_delivery_date: string,
    store_address: string,
    request_comments: string,
}

const AllocationOrderAndRequest = (props: Props) => {
    const isAmReadOnly = useAuthStore((state: AuthStore) => state.moduleAccess.am.isReadOnly)
    const screen = useResponsive();

    const STATUS = Object.keys(ALLOCATION_REQUEST_STATUS).map(key => ({
        value: ALLOCATION_REQUEST_STATUS[key], label: formatNameString(key), text: formatNameString(key)
    }))

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statsData, setStatsData] = useState<any[]>([]);
    const [tableData, setTableData] = useState<any[]>([]);
    const [customerList, setCustomerList] = useState<any[]>([]);
    const [assetPresetList, setAssetPresetList] = useState<any[]>([]);

    const [filters, setFilters] = useState<{ [key: string]: string }>({
        order_number: '',
    });


    const [filterDropdownState, setDropdownState] = useState<any>({
        value: ALLOCATION_REQUEST_TYPES.request.value,
        label: ALLOCATION_REQUEST_TYPES.request.label,
    })


    const emptyModalData: AllocationData = {
        master_data_refID: "",
        datetime: "",
        expected_return_date: "",
        asset_IDs: [],
        assets: [{
            asset_ID: '',
            asset_name: ''
        }],
        dispatch_date: "",
        dispatch_comments: "",
        csv_s3url: ""

    }

    const emptyRequest: RequestData = {
        type: 'request',
        request_order_number: '',
        master_data_refID: "",
        quantity: "",
        customer_ID: '',
        customer: '',
        storeList: [],
        store: '',
        datetime: "",
        expected_delivery_date: "",
        asset_IDs: [],
        store_address: '',
        request_comments: "",

    }

    const [modalData, setModalData] = useState<any>(emptyModalData);
    const [requestData, setRequestData] = useState<any>(emptyRequest);

    const isModalOpenInit = {
        isAllocateOpen: false,
        isRetrieveOpen: false,
        isNewRequest: false,
        isRequestEdit: false,
        isView: false,
    }
    const [isModalOpen, setIsModalOpen] = useState<{ isAllocateOpen: boolean, isRetrieveOpen: boolean, isNewRequest: boolean, isRequestEdit: boolean, isView: boolean }>(isModalOpenInit);

    const getTableData = async (searchUrl?: string) => {
        console.log('getTableData 2222', filters, filterDropdownState)
        let url = ''
        if (searchUrl && searchUrl != '') {
            url = searchUrl
        } else {
            console.log('getTableData2', filters)

            const finalFilter = {
                ...filters,
                type: filterDropdownState.value
            }

            url = getFilterSearchUrl(finalFilter)
        }

        console.log('getTableData33 ', url)


        try {
            setIsLoading(true)
            const result = await AllocationRequestAPI.searchAllocationRequest(url)
            if (result && result.length > 0) {
                setTableData(result)
            } else {
                message.info('No result found')
                setTableData([])
            }


        } catch (err) {
            console.log('getTableData allocation fail...', err)
            setTableData([])


        } finally {
            setIsLoading(false)
        }
    }

    const getSummary = async () => {
        try {
            const result = await SummaryAPI.getSummary('allocation_order')
            const allocation_order = result
            console.log('getSummary allocation order ', allocation_order)
            const statsData = [
                {
                    text: 'Total Requests',
                    number: allocation_order.total || 0,
                },
                {
                    text: 'New Orders',
                    number: allocation_order.request || 0,
                },
                {
                    text: 'New Retrievals',
                    number: allocation_order.retrieval || 0,
                },
                {
                    text: 'Completed',
                    number: allocation_order.completed || 0,
                }
            ]
            setStatsData(statsData)

        } catch (error) {
            console.log('getSummary error allocation request', error)
            setStatsData([])

        }

    }



    const getValueByTitle = (title: string) => {
        console.log('alertCards  ----------------', statsData)
        const item = statsData?.find((item: any) => item.text === title);
        return item ? item.number : 0;
    };

    const analyticsInfo = [
        {
            icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
            title: 'Total Requests',
            value: getValueByTitle('Total Requests'),
            routeTo: '',
        },
        {
            icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
            title: 'New Orders',
            value: getValueByTitle('New Orders'),
            routeTo: '',
        },
        {
            icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
            title: 'New Retrievals',
            value: getValueByTitle('New Retrievals'),
            routeTo: '',
        },
        {
            icon: <img src={ActiveAssetIcon} alt="AllGridIcon" />,
            title: 'Completed',
            value: getValueByTitle('Completed'),
            routeTo: '',
        },
    ];

    const getCustomers = async () => {
        try {
            const result = await CustomerStoreAPI.getCustomerList()
            console.log('getCustomers allocation order ', result)
            setCustomerList(result)
        } catch (error) {
            console.log('getCustomers error allocation request', error)
            setCustomerList([])

        }

    }

    const getDropdowns = async () => {

        const payload = {
            "asset_presets": true,
        }
        try {
            const result = await DropdownAPI.getDropdowns(payload)
            const { asset_presets } = result
            setAssetPresetList(asset_presets)

        } catch (err) {
            console.log('getDropdowns fail...', err)
            setAssetPresetList([])

        }
    }

    // useEffect(getDropdowns, [])

    const initialize = useCallback(
        () => {
            getTableData()
            getSummary()
            getCustomers()
            getDropdowns()
        },
        [filterDropdownState.value],
    )
    useEffect(initialize, [filterDropdownState.value])

    const handleReject = async (record: any) => {
        console.log('deleteRequest record', record)

        try {
            const { master_data_refID, datetime } = record
            const payload = {
                master_data_refID,
                datetime,
                status: 'rejected'
            }
            await AllocationRequestAPI.updateAllocationRequest(payload)
            message.success(`Rejected request data successfully, thank you`)

            initialize()
        } catch (err: any) {
            console.log('delete fail...', err)
            message.error(err.message)
        }

        // DELETE version
        // const url = `${ BIZ_CODE } /allocation_requests?master_data_refID=${record.master_data_refID}&datetime=${record.datetime}`
        // ASSET_MANAGEMENT_API({
        //     url,
        //     method: 'DELETE',

        // }).then(res => {
        //     message.success(`Delete request data successfully, thank you`)
        //     console.log('delete suc...', res)
        //     getTableData()

        // }).catch(err => {
        //     message.error(err.message)
        //     console.log('delete fail...', err)
        // })
    }

    const menu = (record: any) => (
        <Menu className='dropdownMenu'>
            {record.type == ALLOCATION_REQUEST_TYPES.request.value ? (
                <>
                    {record.status === ALLOCATION_REQUEST_STATUS.OUTSTANDING ? (
                        <>
                            <Menu.Item key="1">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            e.preventDefault()
                                            console.log('onClick record', record)
                                            handleModalOpen(e, 'isRequestEdit', record)
                                        }
                                    }
                                >Edit</div>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            ADI_CONFIRM({
                                                customizeTitle: `Are you sure you want to reject ${record.order_number}`,
                                                isdelete: true,
                                                onConfirm: () => {
                                                    console.log('Delete', record)
                                                    handleReject(record)
                                                }
                                            })

                                        }
                                    }
                                >Reject</div>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            e.preventDefault()
                                            handleModalOpen(e, 'isAllocateOpen', record)
                                        }
                                    }>Allocate</div>
                            </Menu.Item>
                        </>

                    ) : record.status === ALLOCATION_REQUEST_STATUS.PARTIALLY_ALLOCATED ? (
                        <>
                            <Menu.Item key="4">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            e.preventDefault()
                                            // handleModalOpen(e, 'isAllocateOpen', record)
                                            // handleUpdateRequest(e, record, ALLOCATION_REQUEST_STATUS.DISPATCHED)
                                            handleModalOpen(e, 'isAllocateOpen', record)
                                        }
                                    }>Allocate</div>
                            </Menu.Item>
                        </>
                    ) : record.status === ALLOCATION_REQUEST_STATUS.ALLOCATED ? (
                        <>
                            <Menu.Item key="4">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            e.preventDefault()
                                            // handleModalOpen(e, 'isAllocateOpen', record)
                                            handleUpdateRequest(e, record, ALLOCATION_REQUEST_STATUS.DISPATCHED)
                                        }
                                    }>Dispatch</div>
                            </Menu.Item>
                        </>
                    ) : record.status === ALLOCATION_REQUEST_STATUS.DISPATCHED ? (
                        <>
                            <Menu.Item key="5">
                                <div
                                    onClick={
                                        (e: MouseEvent) => {
                                            e.preventDefault()
                                            // handleModalOpen(e, 'isAllocateOpen', record)
                                            handleUpdateRequest(e, record, ALLOCATION_REQUEST_STATUS.DELIVERED)
                                        }
                                    }>Mark as Delivered</div>
                            </Menu.Item>
                        </>
                    ) : (

                        <Menu.Item key="6">
                            <div
                                onClick={
                                    (e: MouseEvent) => {
                                        e.preventDefault()
                                        handleModalOpen(e, 'isView', record)
                                    }
                                }>View</div>
                        </Menu.Item>
                    )}

                </>
            ) : null}


            {record.type === ALLOCATION_REQUEST_TYPES.retrieval.value ? (
                <>
                    {record.status === ALLOCATION_REQUEST_STATUS.OUTSTANDING ? (
                        <Menu.Item key="1">
                            <div
                                onClick={
                                    (e: MouseEvent) => {
                                        e.preventDefault()
                                        handleModalOpen(e, 'isRetrieveOpen', record)
                                    }
                                }>Mark as Retrieved</div>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="2">
                            <div
                                onClick={
                                    (e: MouseEvent) => {
                                        e.preventDefault()
                                        handleModalOpen(e, 'isView', record)
                                        // TODO: need to check do we need to show view or not
                                    }
                                }>View</div>
                        </Menu.Item>
                    )}
                </>

            ) : null}
        </Menu>
    );

    const actionColumn = isAmReadOnly ? [] : [
        {
            title: formatTitle('Actions'),
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 120,
            align: 'center',
            render: (text: any, record: any, index: number) => {

                return (

                    <Dropdown overlay={menu(record)}>
                        <ActionIcon style={{ color: 'white', fontSize: '20px' }} onClick={(e: any) => e.stopPropagation()} />
                    </Dropdown>

                )
            },
        },
    ]

    console.log('cust listtttt', customerList)
    console.log('assetPresetList listtttt', assetPresetList)

    const columns: any = [
        // {
        //     title: formatTitle("Order No"),
        //     dataIndex: "order_number",
        //     align: "left",
        //     key: "order_number",

        // },
        {
            title: formatTitle("Request ID"),
            dataIndex: "order_number",
            key: "order_number",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
        },
        {
            title: formatTitle("Date & Time"),
            dataIndex: "datetime",
            key: "datetime",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? moment(text).format(DATE_TIME_FORMAT) : initialCellValue(text)
        },
        {
            title: formatTitle("Customer ID"),
            dataIndex: "customer_ID",
            key: "customer_ID",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
        },
        {
            title: formatTitle("Customer Name"),
            dataIndex: "customer",
            align: "left",
            key: "customer",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
            filters: customerList.length > 0 ? customerList.map(item => ({
                value: item.customer_name,
                label: item.customer_name,
                text: item.customer_name
            })) : [],
            onFilter: (value: any, record: any) => record?.customer === value,
        },
        {
            title: formatTitle("Asset Preset"),
            dataIndex: "master_asset_name",
            key: "master_asset_name",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue,
            filters: assetPresetList.length > 0 ? assetPresetList.map(item => ({
                value: item.preset_name,
                label: item.preset_name,
                text: item.preset_name
            })) : [],
            onFilter: (value: any, record: any) => record?.master_asset_name === value,
        },
        {
            title: formatTitle("Quantity"),
            dataIndex: "quantity",
            key: "quantity",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
        },
        // {
        //     title: "Asset ID",
        //     dataIndex: "asset_ID",
        //     key: "asset_ID",
        // },
        {
            title: formatTitle("Delivery Address"),
            dataIndex: "store_address",
            key: "store_address",
            ...TABLE_CELL_CONFIG,
            render: initialCellValue
            // render: (text: any, record: any, index: number) => {
            //     // console.log('recordstore_name===>', record, record.store_address)
            //     let combinedAddress: string = '--';

            //     combinedAddress = `${record.store_address.store_address_line_1} ${record.store_address.store_country} ${record.store_address.store_state} ${record.store_address.store_postcode}`

            //     return combinedAddress
            // }

        },
        {
            title: formatTitle(requestData.type == ALLOCATION_REQUEST_TYPES.retrieval.value ? "Expected Pick Date" : "Expected Delivery Date"),
            dataIndex: "expected_delivery_date",
            key: "expected_delivery_date",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => text ? moment(text).format('DD/MM/YYYY') : initialCellValue(text)

        },
        // {
        //     title: formatTitle("Return Date"),
        //     dataIndex: "master_data_refID",
        //     align: "center",
        //     key: "master_data_refID",
        // },
        {
            title: formatTitle("Status"),
            dataIndex: "status",
            key: "status",
            ...TABLE_CELL_CONFIG,
            render: initialEnumCellValue,
            filters: STATUS,
            onFilter: (value: any, record: any) => record?.status === value,

        },
        {
            title: formatTitle("Comments"),
            dataIndex: "request_comments",
            key: "request_comments",
            ...TABLE_CELL_CONFIG,
            render: (text: any, record: any, index: number) => (
                <>
                    {record.request_comments.length > 0 ? record.request_comments : '--'}
                </>
            ),
        },

        ...actionColumn
    ];


    const handleUpdateRequest = async (e: any, record: any, changeToStatus: string) => {
        console.log('handleUpdateRequest', record)
        const { master_data_refID, datetime } = record
        try {
            const payload: any = {
                master_data_refID,
                datetime,
                status: changeToStatus
            }
            console.log("handleUpdateRequest sensor res payload", payload);
            await AllocationRequestAPI.updateAllocationRequest(payload)
            message.success(`Request updated successfully, thank you`)

            initialize()


        } catch (err: any) {
            console.log('upload id file fail...', err.response, err.message)
            message.error(`Request updated fail: ${err.response.data}`)


        }

    }

    const handleModalOpen = async (e: MouseEvent, type: 'isAllocateOpen' | 'isRetrieveOpen' | 'isNewRequest' | 'isRequestEdit' | 'isView', curRecord?: any) => {
        console.log('handleModalOpen', type, curRecord)
        e.preventDefault()

        setIsModalOpen({
            ...isModalOpen,
            [type]: true,
        })
        if (type == 'isAllocateOpen' || type == 'isRetrieveOpen') {
            const finalData = {
                ...modalData,
                ...curRecord,
                store_address: curRecord ? `${curRecord?.store_address}` : '',
                // assets: [...emptyAssetArr],
                expected_delivery_date: curRecord ? new Date(curRecord.expected_delivery_date) : '',
            }
            setModalData(finalData)
        } else {

            setRequestData(curRecord)

        }
    };

    const onSelectType = (value: string, selectorName: string) => {
        // console.log("onSelectLevel", e, selectorName);
        const selectedItem = value;
        const latestFilters = {
            ...filters,
            [selectorName]: selectedItem
        }
        setFilters(latestFilters)

        if (selectorName !== 'order_number') {

            handleSearch(latestFilters)
        }



    };

    const handleSearch = (latestFilters?: any) => {
        console.log('handleSearch', filters)
        let curFilters: any

        // for selector search
        if (latestFilters) {
            curFilters = { ...latestFilters }
        }
        // for input enter search
        else {
            curFilters = { ...filters }
        }

        const searchUrl = getFilterSearchUrl(curFilters)

        if (searchUrl !== '') {
            getTableData(searchUrl + `&type=${filterDropdownState.value}`)

        } else {
            getTableData()

        }


    }


    const flexOption = {
        column: !screen.md,
        alignStart: !screen.md,
        gap: "10px"
    }

    const renderOptions = () => {

        const options = Object.keys(ALLOCATION_REQUEST_TYPES).map((key: any, index: number) => {
            return {
                label: ALLOCATION_REQUEST_TYPES[key].label,
                value: ALLOCATION_REQUEST_TYPES[key].value,
            }
        })
        return getSelectorOptions(options, '', 'value', 'label')

    }

    return (
        <>

            {/* <div style={{ marginBottom: '20px' }}>
                <DisplayStats data={statsData} />
            </div> */}
            {/* <div style={{ marginBottom: '20px' }}>
                <Analytics items={analyticsInfo} />
            </div> */}
            <TableCard>
                <TrackGridTwo style={
                    {
                        padding: '0 0 0 10px',
                    }
                }>
                    <FlexWrapper flexStart {...flexOption} >
                        {
                            !isAmReadOnly &&

                            <Input style={{ width: '300px' }}
                                type="select"
                                options={renderOptions()}
                                defaultValue={ALLOCATION_REQUEST_TYPES.request.value}
                                onChange={(e: any) => {

                                    console.log('aaaa', e)
                                    setDropdownState({
                                        ...filterDropdownState,
                                        value: e,
                                        label: ALLOCATION_REQUEST_TYPES[e].label
                                    })

                                }}
                            />
                        }

                        <div style={{ marginLeft: screen.md ? '10px' : "" }}>
                            <Input

                                placeholder="Search by Order No"
                                name="order_number "
                                type="search"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onSelectType(e.target.value, 'order_number')}
                                value={filters.order_number}
                                onSearch={() => handleSearch()}
                                allowClear
                            />
                        </div>
                    </FlexWrapper >
                    {
                        !isAmReadOnly &&
                        <FlexWrapper flexEnd={screen.md} flexStart style={{ marginTop: screen.md ? "" : "10px" }} >
                            <SimpleButton
                                text="+ New Request"
                                onClick={(e: MouseEvent) => {
                                    handleModalOpen(e, 'isNewRequest')
                                    setRequestData({ ...emptyRequest })
                                }}
                            />
                        </FlexWrapper>
                    }

                </TrackGridTwo>

                <AdiTable
                    loading={isLoading}
                    tableData={tableData}
                    fullWidth
                    columns={columns}
                    marginTop='20px'
                    // tableWrapperStyle={{ width: '99%' }}
                    scroll={{ y: FIXED_TABLE_HEIGHT, x: '100%' }}
                    extraPaginationPadding
                />
            </TableCard>

            <AllocationModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                modalData={modalData}
                setModalData={setModalData}
                refreshPage={initialize}
            />


            <RequestModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                requestData={requestData}
                setRequestData={setRequestData}
                refreshPage={initialize}


            />
        </>
    )
}

export default AllocationOrderAndRequest;