import { Col, Divider, Form, Radio, RadioChangeEvent, Row, Switch, message, Checkbox, Modal } from 'antd';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import GlobalModal from 'components/GlobalModal';
import GlobalDrawer from 'components/GlobalModal/GlobalDrawer';
import Input from 'components/Input';
import React, { useEffect, useState } from 'react'
import { FlexWrapper, HeadingLabel, SubHeadingLabel, TrackGridTwo } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { PRIORITY_TYPES, RECALLEPCIDOPTIONS, RECALLTYPEOPTIONS } from 'utilities/CONSTANTS';
import VALIDATION_RULES from 'utilities/inputValidation';
import RecallAPI from '../apis/RecallAPI';
import { convertDateAndTime } from 'utilities/Functions/GlobalHelperFunctions';
import AddressSearchInputFormItem from 'components/AddressSearchInput/AddressSearchInputFormItem';
import EpcAPI from '../apis/EpcAPI';
import ProductPartnerAPI from '../../KeyManagement/apis/ProductPartnerAPI';
import { useAuthStore, useGeneralStore } from "zustand-stores";
import { GeneralStore } from "zustand-stores/useGeneralStore";
import { SESSION_STORAGE_KEYS, TABLE_CELL_CONFIG } from "utilities/CONSTANTS";

import { ADI_CONFIRM, getSessionStorage } from "utilities/Functions/GlobalHelperFunctions";

const { ModalLayout } = GlobalDrawer;

type Props = {
  isModalOpen: boolean;
  handleModalClose: Function;
  getTableData: Function;
  selectedRecall?: any;
  isView?: boolean;
}


type FormTypes = {
  recallId: string;
  created_timestamp?: string;
  recall_datetime: string;
  recall_name: string;
  report_date: string;
  initiation_date: string;
  epc: string;
  batch_number: string;
  recall_type: string;
  identified_date: string;
  priority: string;
  recall_description: string;
  recall_instructions: string;
  recall_strategy: string;
  recall_initiator: string;
  email: string;
  phone_no: string;
  address: string;
  press_release: string;
  inform_supply_chain_partners: string;
  status?: string;
}

const RecallModal = (props: Props) => {
  const { isModalOpen, handleModalClose, selectedRecall, isView, getTableData } = props
  const [recallForm] = Form.useForm();
  const [epcOptions, setEpcOptions] = useState<any[]>([]);
  const [recallInitiatorOptions, setRecallInitiatorOptions] = useState<any[]>([]);
  const [partners, setPartners] = useState<any[]>([]);
  const BIZ_CODE = getSessionStorage(SESSION_STORAGE_KEYS.BIZ_CODE);
  const setIsGlobalLoading = useGeneralStore(
    (state: GeneralStore) => state.setIsGlobalLoading
  );
  const emptyData: FormTypes = {
    recallId: "",
    recall_datetime: "",
    recall_name: "",
    report_date: "",
    initiation_date: "",
    epc: "",
    batch_number: "",
    recall_type: "",
    identified_date: "",
    priority: "",
    recall_description: "",
    recall_instructions: "",
    recall_strategy: "",
    recall_initiator: "",
    email: "",
    phone_no: "",
    address: "",
    press_release: "",
    inform_supply_chain_partners: "",

  }

  const [toggleSelection, setToggleSelection] = useState({
    press_release: false,
    inform_supply_chain_partners: false
  });

  const [logData, setLogData] = React.useState<FormTypes>({ ...emptyData });

  const [relatedEpcsModal, setRelatedEpcsModal] = useState(false);
  const [relatedEpcs, setRelatedEpcs] = useState<string[]>([]);

  useEffect(() => {
    if (selectedRecall) {
      recallForm.setFieldsValue({
        ...selectedRecall
      })
    } else {

      const get_id = async () => {
        const id_res = await RecallAPI.getId()
        console.log(' id333 recall', id_res)

        const updatedData = {
          ...emptyData,
          recallId: id_res
        }

        setLogData(updatedData)
        recallForm.setFieldsValue({
          ...updatedData,

        })

      }
      if (isModalOpen) {
        get_id()
      }


    }
  }, [isModalOpen]);
  useEffect(() => {
    if (selectedRecall) {
      recallForm.setFieldsValue({
        ...selectedRecall
      })
    }
  }, [selectedRecall, isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      getEpcOptions();
      getPartnersOptions();
    }
  }, [isModalOpen]);

  const getEpcOptions = async () => {
    try {
      const response = await EpcAPI.getEpcsByEventType('AggregationEvent');
      if (response?.body) {
        const formattedOptions = response.body.map((item: any) => ({
          label: item.epc,
          value: item.epc,
          batchNumber: item.batchNumber
        }));
        setEpcOptions(formattedOptions);
      }
    } catch (error) {
      console.error('Error fetching EPC options:', error);
      message.error('Failed to load EPC options');
    }
  };

  const getPartnersOptions = async () => {
    try {
      const allPartners = await ProductPartnerAPI.getAllSupplyChainPartners(BIZ_CODE);
      setPartners(allPartners);

      const options = allPartners.map((partner: any) => ({
        label: partner.business_name + ' (' + partner.role + ')',
        value: partner.business_name
      }));

      setRecallInitiatorOptions(options);
    } catch (error) {
      console.error('Error fetching partners:', error);
      message.error('Failed to load partner options');
    }
  };

  const disabledDateFunction = (current: any) => {
    return current && current < new Date().setHours(0, 0, 0, 0)
  };

  const handleClose = () => {
    setToggleSelection({
      press_release: false,
      inform_supply_chain_partners: false
    });
    handleModalClose()
    recallForm.resetFields()
  }
  const handleConfirmed = async (values: any) => {
    try {
      setIsGlobalLoading(true);
      const loadingMessage = values.recall_batch
        ? "Recording individual recalls and sending emails, this may take a few seconds..."
        : undefined;
      const payload = {
        ...values,
        report_date: convertDateAndTime(values.report_date),
        initiation_date: convertDateAndTime(values.initiation_date),
        identified_date: convertDateAndTime(values.identified_date),
        press_release: toggleSelection.press_release,
        inform_supply_chain_partners: toggleSelection.inform_supply_chain_partners,
        to_notify: toggleSelection.inform_supply_chain_partners ? partners : [],
        epcs: values.recall_batch ? relatedEpcs : [],
        is_batch_recall: values.recall_batch
      }
      if (loadingMessage) {
        message.loading({ content: loadingMessage, key: 'recallLoading', duration: 0 });
      }
      const res = await RecallAPI.addRecall(payload)
      setToggleSelection({
        press_release: false,
        inform_supply_chain_partners: false
      });
      message.destroy('recallLoading');

      if (res?.data?.statusCode === "201") {
        message.success(`Recall added successfully, thank you`)
        getTableData()
        handleClose()
      } else {
        message.error('Failed to save recall');
      }
    } catch (error) {
      console.error('Error saving recall:', error);
      message.destroy('recallLoading');
      message.error('Failed to save recall');
    } finally {
      setIsGlobalLoading(false);
    }
  }

  const handleInitiatorChange = (value: any) => {
    const selectedPartner = partners.find(partner => partner.business_name === value);

    recallForm.setFieldsValue({
      recall_initiator: value,
      email: selectedPartner?.business_email || '',
      phone_no: selectedPartner?.business_phone || '',
      address: selectedPartner?.business_address || ''
    });
  };

  const handleEpcChange = (value: string) => {
    const selectedOption = epcOptions.find(option => option.value === value);
    if (selectedOption) {
      recallForm.setFieldsValue({
        batch_number: selectedOption.batchNumber,
        recall_batch: false
      });
    }
  };

  const handleBatchCheckChange = async (checked: boolean) => {
    if (isView) return;

    if (checked) {
      try {
        setIsGlobalLoading(true);
        const selectedEpc = recallForm.getFieldValue('epc');
        const selectedBatch = recallForm.getFieldValue('batch_number');

        if (selectedEpc && selectedBatch) {
          const response = await EpcAPI.getEpcsByBatchNumber(selectedBatch);
          setRelatedEpcs(response?.body || []);
          setRelatedEpcsModal(true);

        }
      } catch (error) {
        console.error('Error fetching related EPCs:', error);
        message.error('Failed to fetch related EPCs');
        recallForm.setFieldsValue({ recall_batch: false });
      } finally {
        setIsGlobalLoading(false);
      }
    } else {
      recallForm.setFieldsValue({ recall_batch: false });
    }
  };

  const handleBatchRecallConfirm = () => {
    recallForm.setFieldsValue({
      recall_batch: true,
      epcs: relatedEpcs
    });
    setRelatedEpcsModal(false);
  };

  const renderFooterBtns = () => (
    <FlexWrapper flexEnd key='0' gap='10px'>
      <SimpleButton
        text='Cancel'
        id='modal-btn-width-regular'
        onClick={handleClose}
        isCancel
      />
      {isView ? null : (
        <SimpleButton
          id='modal-btn-width-regular'
          form='recall-form'
          className="ms-3"
          htmlType="submit"
          text={`${isView ? 'Close' : 'Save'}`}
        />
      )}

    </FlexWrapper>
  )
  return (
    <>
      <GlobalDrawer
        open={isModalOpen}
        onClose={handleClose}
        title={`${isView ? 'Recall (View Only)' : 'New Recall'}`}
        width={600}
        footer={renderFooterBtns()}
      >
        <Form
          form={recallForm}
          name='recall-form'
          onFinish={(values: any) => handleConfirmed(values)}
        >
          <ModalLayout>
            <ModalLayout.ModalSection title="Recall Details">
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recallId'
                  >
                    <Input
                      label='Recall ID'
                      placeholder={`Recall ID`}
                      name="recallId"
                      type="text"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recall_name'
                  >
                    <Input
                      label='Recall Name'
                      placeholder={`Recall Name`}
                      name="recall_name"
                      type="text"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='report_date'
                    rules={[{ required: true, message: "Please fill in Report Date" }]}
                  >
                    <Input
                      label='Report Date'
                      placeholder={`Report Date`}
                      name="report_date"
                      disabledDate={disabledDateFunction}
                      type="date"
                      disabled={isView}
                      required
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='initiation_date'
                    rules={[{ required: true, message: "Please fill in Initiation Date" }]}
                  >
                    <Input
                      label='Initiation Date'
                      placeholder={`Initiation Date`}
                      disabledDate={disabledDateFunction}
                      name="initiation_date"
                      type="date"
                      disabled={isView}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>
          </ModalLayout>
          <ModalLayout>
            <ModalLayout.ModalSection title="Product Details">
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 18 }}>
                  <Form.Item
                    name="epc"
                    rules={[
                      {
                        required: true,
                        message: 'Please select EPC',
                      }
                    ]}
                  >
                    <Input
                      type='select'
                      label='EPC'
                      placeholder="Select EPC"
                      required
                      disabled={isView}
                      options={epcOptions}
                      onChange={handleEpcChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 6 }}>
                  <Form.Item
                    name='batch_number'
                  >
                    <Input
                      label='Batch/Lot No'
                      placeholder={`Batch/Lot No`}
                      name="batch_number"
                      type="text"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="recall_batch"
                    valuePropName="checked"
                  >
                    <Checkbox
                      onChange={(e) => handleBatchCheckChange(e.target.checked)}
                      style={{ marginTop: '8px' }}
                      disabled={isView}
                    >
                      <span style={{ color: '#fff' }}>Recall entire batch</span>
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Recall Reason">
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='recall_type'
                    rules={[{ required: true, message: 'Please select Recall Type/Reason' }]}
                  >
                    <Input
                      label='Recall Type/Reason'
                      placeholder={`Recall Type`}
                      name="recall_type"
                      type="select"
                      options={RECALLTYPEOPTIONS}
                      required
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='identified_date'
                  >
                    <Input
                      label='Identified On'
                      placeholder={`Select Date`}
                      disabledDate={(current: any) => false}
                      name="identified_date"
                      type="date"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='priority'
                  >
                    <Input
                      label='Priority'
                      placeholder={`Select Priority`}
                      name="priority"
                      type="select"
                      options={[{
                        label: 'High', value: 'High'
                      },
                      {
                        label: 'Medium', value: 'Medium'
                      },
                      {
                        label: 'Low', value: 'Low'
                      }]}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_description'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Description'
                      placeholder={`Describe the problem that resulted in a need for Recall.`}
                      name="recall_description"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='potential_consequence'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Potential Consequences'
                      placeholder={`Describe the potential impact to customer health, customer satisfaction, company reputation and company finances.`}
                      name="potential_consequence"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Resolution">
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_instructions'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Instructions'
                      placeholder={`Clear instructions on what consumers should do if they have the affected product (e.g., stop using it, return it to the store, contact the manufacturer, etc.)`}
                      name="recall_instructions"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_strategy'
                  >
                    <Input
                      style={{ resize: 'none' }}
                      label='Recall Strategy'
                      placeholder={`Description of the plan of action for the recall. This is required for reporting to regulatory agencies  - includes different phases of the recall.`}
                      name="recall_strategy"
                      type="textarea"
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </ModalLayout.ModalSection>

          </ModalLayout>
          <ModalLayout>

            <ModalLayout.ModalSection title="Contact Information">
              <Row gutter={[15, 0]}>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='recall_initiator'
                  >
                    <Input
                      label='Recall Initiator'
                      placeholder={`Person or Company initiating Recall.`}
                      name="recall_initiator"
                      type="select"
                      required
                      options={recallInitiatorOptions}
                      onChange={handleInitiatorChange}
                      disabled={isView}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='email'
                    rules={[{ type: 'email', message: "Please enter a valid email" }]}
                  >
                    <Input
                      label='Email Address'
                      placeholder={`Email Address`}
                      name="email"
                      type="email"
                      required
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Form.Item
                    name='phone_no'
                  >
                    <Input
                      label='Phone No'
                      placeholder={`Phone No`}
                      name="phone_no"
                      required
                      type="phone"
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name='address'
                    rules={[{ required: true, message: 'Please enter the Address.' }]}
                  >
                    <Input
                      label='Address'
                      placeholder='Enter Address'
                      name="address"
                      type="text"
                      disabled={true}
                      required
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
                  <SubHeadingLabel>Issue 'Press Release'</SubHeadingLabel>
                  <Form.Item
                    name='press_release'
                  >
                    <SwitchDiv>
                      <SwitchContainer color={toggleSelection.press_release}
                        onChange={(checked: boolean) => {
                          console.log('checked', checked)
                          setToggleSelection({
                            ...toggleSelection,
                            press_release: checked
                          })
                        }}
                        disabled={isView}
                      />
                    </SwitchDiv>
                  </Form.Item>
                </div>
                <div style={{ display: 'flex', gap: '10px', alignItems: 'baseline' }}>
                  <SubHeadingLabel>Inform Supply Chain Partners</SubHeadingLabel>
                  <Form.Item
                    name='inform_supply_chain_partners'
                  >
                    <SwitchDiv>
                      <SwitchContainer color={toggleSelection.inform_supply_chain_partners}
                        onChange={(checked: boolean) => {
                          setToggleSelection({
                            ...toggleSelection,
                            inform_supply_chain_partners: checked
                          })
                        }}
                        disabled={isView}
                      />
                    </SwitchDiv>
                  </Form.Item>
                </div>
              </div>
            </ModalLayout.ModalSection>

          </ModalLayout>
        </Form>
      </GlobalDrawer>

      <Modal
        title={<div style={{ fontSize: '16px', fontWeight: 500, color: '#fff' }}>Batch Recall Confirmation</div>}
        open={relatedEpcsModal}
        onOk={handleBatchRecallConfirm}
        onCancel={() => {
          setRelatedEpcsModal(false);
          recallForm.setFieldsValue({ recall_batch: false });
        }}
        okText="Confirm Recall"
        cancelText="Cancel"
        width={500}
        okButtonProps={{ disabled: isView }}
        cancelButtonProps={{ disabled: isView }}
        style={{
          backgroundColor: 'var(--modal-body-color)'
        }}
        modalRender={modal => (
          <div style={{
            background: 'var(--modal-body-color)',
            borderRadius: '4px',
          }}>
            {modal}
          </div>
        )}
        className="custom-modal"
      >
        <div style={{ padding: '10px 0' }}>
          <p style={{ fontSize: '14px', marginBottom: '15px', color: '#fff' }}>
            {`${relatedEpcs.length} EPCs found associated with this batch.`}
          </p>
          <div
            style={{
              maxHeight: '200px',
              overflow: 'auto',
              margin: '15px 0',
              padding: '10px',
              background: '#383A56',
              borderRadius: '4px',
              border: '1px solid #707070'
            }}
          >
            {relatedEpcs.map((epc, index) => (
              <div key={index} style={{ padding: '4px 0', color: '#C7C9D9' }}>{epc}</div>
            ))}
          </div>
          <p style={{ fontSize: '14px', color: '#ff4d4f' }}>
            Are you sure you want to recall all these items?
          </p>
        </div>
      </Modal>
    </>
  )
}

export default RecallModal

type switchProps = {
  color?: boolean;
}

const SwitchDiv = styled.div`
  .ant-switch {
    background-color: #E4E4EB !important;
    border: 1px solid #E4E4EB !important;
  }
`;
const SwitchContainer = styled(Switch)`
  .ant-switch-handle::before {
    background-color: ${(props: switchProps) => {
    return props.color ? `green` : `red`;
  }}!important;
  }
`;