import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, message, Row, Tooltip } from 'antd';
import AdiTable from 'components/AdiTable';
import SimpleButton from 'components/GlobalButtons/SimpleButton';
import Input from 'components/Input';
import InventoryItemAPI from 'containers/Pages/InventoryManagement/apis/InventoryItemAPI';
import React, { useState, useEffect, MouseEvent } from 'react'
import { ActionButton, DisplayEmptyTextPrimary, DisplayEmptyTextSecondary } from 'statics/styles/StyledComponents';
import styled from 'styled-components';
import { PRODUCT_FLOW_STEPS, TABLE_CELL_CONFIG } from 'utilities/CONSTANTS';
import { formatTitle, getFormattedMessage, initialCellValue, } from 'utilities/Functions/FormatFunctions';
import { ADI_CONFIRM } from 'utilities/Functions/GlobalHelperFunctions';


import { ColumnOptionsType } from 'components/GlobalButtons/ColumnSelectButton';
import CategoryAPI from 'containers/Pages/InventoryManagement/apis/CategoryAPI';
import FlowInfoPanel from 'components/FlowInfoPanel';
import { PRODUCT_TYPE } from 'containers/Pages/InventoryManagement/statics/constants';
import { useNavigate } from 'react-router-dom';
import { OSM_ROUTES } from 'containers/Pages/InventoryManagement/OSMRoutes';
import { useGeneralStore } from 'zustand-stores';
import { GeneralStore } from 'zustand-stores/useGeneralStore';
import TableCard from 'components/AdiTable/TableCard';
import EmptyDisplay from 'components/AdiTable/EmptyDisplay';
import { SPM_ROUTES } from 'containers/Pages/StockAndProductionManagement/SPMRoutes';
import { ProcessMap } from 'components/ProcessMap';

const flexibleColumns = [

  {
    title: formatTitle('LEAD DELIVERY TIME'),
    dataIndex: 'lead_time_for_replenishement',
    key: 'lead_time_for_replenishement',
    ...TABLE_CELL_CONFIG,
    render: initialCellValue,
    sorter: (a: any, b: any) => a.reorder_level?.localeCompare(b.reorder_level)
  },


]

const selectColumnOptions: ColumnOptionsType = flexibleColumns.map((item: any) => {
  return {
    text: formatTitle(item.title),
    valueKey: item.dataIndex,
  }
})

const defaultTablesColumns = selectColumnOptions.map(item => item.valueKey)


type Props = {
  active: boolean,
}

const Items = ({ active }: Props) => {
  const navigate = useNavigate(); // Use the useNavigate hook instead of useHistory
  const [data, setData] = useState<any>()
  const [filterData, setFilterData] = useState<any[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [tableHeaderFilters, setTableHeaderFilters] = useState<any>({
    categories: [],
    subCategories: [],
  })

  const setIsGlobalLoading = useGeneralStore((state: GeneralStore) => state.setIsGlobalLoading)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (active) {
      setIsLoading(true)
      Promise.all([
        getTableData(),
        getTableFilters()
      ]).finally(() => {
        setIsLoading(false)
      })

    }

  }, [active])

  const getTableData = async () => {
    await InventoryItemAPI.getAllItems().then((res: any) => {
      console.log('get all inventory item succ...', res)
      const result = res
      // sort the data by created_date
      Array.isArray(result) && result.sort((a: any, b: any) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
        .forEach((row: any, index: number) => {
          row.key = index + 1
        })

      console.log('result==>', result)
      setData(result)
      // setFilterData(res)
      onTextSearch(searchText, result)


    }).catch(err => {
      console.log('Create inventory item fail...', err)

    })
  }

  const getTableFilters = async () => {
    const filterList: any = {
      categories: [],
      subCategories: [],
    }

    try {
      // get categories
      const categories = await CategoryAPI.getAllCategories()
      const cateNames = categories.map((item: any) => item.item_category)
      if (Array.isArray(cateNames)) {
        cateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.categories = cateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item categories error...', e)
    }
    try {
      // get sub categories
      const subCategories = await CategoryAPI.getAllSubCategories()
      const subCateNames = subCategories.map((item: any) => item.item_sub_category)
      if (Array.isArray(subCateNames)) {
        subCateNames.sort((a: any, b: any) => a.localeCompare(b))
        filterList.subCategories = subCateNames.map((item: any) => ({ text: item, value: item }))
      }
    } catch (e) {
      console.log('Get item sub categories error...', e)
    }

    setTableHeaderFilters({ ...filterList })

  }




  const fixedColumns = [

    {
      // this is fixed column
      title: formatTitle('PRODUCT CATEGORY'),
      dataIndex: 'item_category',
      key: 'item_category',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: tableHeaderFilters.categories,
      onFilter: (value: string, record: any) => record.item_category === value,

    },
    {
      title: formatTitle("PRODUCT SubCategory"),
      dataIndex: "item_sub_category",
      key: "item_sub_category",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      filters: tableHeaderFilters.subCategories,
      onFilter: (value: string, record: any) => record.item_sub_category === value,
    },
    {
      title: formatTitle("Product Name"),
      dataIndex: "item_name",
      key: "item_name",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.item_name?.localeCompare(b.item_name)
    },
    {
      title: formatTitle("Inventory ID"),
      dataIndex: "sku",
      key: "sku",
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
      sorter: (a: any, b: any) => a.sku?.localeCompare(b.sku)
    },
    {
      title: 'UoM (UNIT OF MEASURE)',
      dataIndex: 'measurement_unit',
      key: 'measurement_unit',
      ...TABLE_CELL_CONFIG,
      render: initialCellValue,
    },
    {
      title: 'INVENTORY TYPE',
      dataIndex: 'mark_finished_product',
      key: 'mark_finished_product',
      ...TABLE_CELL_CONFIG,
      filters: [
        { text: PRODUCT_TYPE.acgProduct.label, value: PRODUCT_TYPE.acgProduct.value },
        { text: PRODUCT_TYPE.generalProduct.label, value: PRODUCT_TYPE.generalProduct.value },
        { text: PRODUCT_TYPE.xplProduct.label, value: PRODUCT_TYPE.xplProduct.value },
        { text: PRODUCT_TYPE.eCommerceProduct.label, value: PRODUCT_TYPE.eCommerceProduct.value },
      ],
      onFilter: (value: string, record: any) => record.mark_finished_product === value,
      render: (text: any, record: any, index: number) => (
        PRODUCT_TYPE.acgProduct.value === text ? PRODUCT_TYPE.acgProduct.label :
          PRODUCT_TYPE.generalProduct.value === text ? PRODUCT_TYPE.generalProduct.label :
            PRODUCT_TYPE.xplProduct.value === text ? PRODUCT_TYPE.xplProduct.label :
              PRODUCT_TYPE.eCommerceProduct.value === text ? PRODUCT_TYPE.eCommerceProduct.label : initialCellValue(text)
      ),
    },
    // {
    //   title: formatTitle('SAFETY STOCK COUNT'),
    //   dataIndex: 'safety_stock_count',
    //   key: 'safety_stock_count',
    //   ...TABLE_CELL_CONFIG,
    //   render: initialCellValue,
    //   sorter: (a: any, b: any) => parseFloat(a.safety_stock_count) - parseFloat(b.safety_stock_count),
    // },
  ]


  const actionColumn = [
    // {
    //   title: formatTitle('Actions'),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   fixed: 'right',
    //   width: 150,
    //   align: 'center',
    //   render: (text: any, record: any, index: number) => (
    //     <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
    //       {/* {
    //         record.mark_finished_product == PRODUCT_TYPE.eCommerceProduct.value ? 'No Action' : */}
    //       <Tooltip title="Edit">
    //         <ActionButton
    //           // title="Edit"
    //           onClick={(e) => {
    //             e.stopPropagation()
    //             e.preventDefault()
    //             navigate(`${OSM_ROUTES.inventoryCreateProduct}?sku=${record.sku}`, { state: { productData: record } });
    //           }}
    //         >
    //           <EditOutlined style={{ fontSize: '15px' }} />
    //         </ActionButton>
    //       </Tooltip>
    //       {/* } */}

    //       {/* <ActionButton
    //         title="Delete"
    //         onClick={(e: MouseEvent) => {
    //           e.stopPropagation()
    //           e.preventDefault()
    //           handleDelete(record)
    //         }}
    //       >
    //         <DeleteOutlined style={{ fontSize: '15px' }} />
    //       </ActionButton> */}
    //     </div>
    //   ),
    // },
    {
      title: formatTitle('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      align: 'center',
      render: (text: any, record: any) => {

        const items = [
          {
            label: 'Edit',
            icon: <EditOutlined />,
            onClick: () => {
              navigate(`${SPM_ROUTES.inventoryCreateProduct}?sku=${record.sku}`, { state: { productData: record } });
            },
          },
        ];

        return <AdiTable.ActionDropdown menuItems={items} />;
      },

    }
  ]

  const finalTableColumns = [
    ...fixedColumns,
    // ...newColArray,
    ...actionColumn
  ]

  const handleDelete = (record: any) => {
    console.log('handleDelete ')
    ADI_CONFIRM({
      actionTitle: 'delete the item',
      isdelete: true,
      onConfirm: () => {
        setIsGlobalLoading(true)
        const key = 'delete'
        // message.loading({ content: getFormattedMessage('delete', 'loading'), key })
        InventoryItemAPI.deleteItem(record.item_name, record.item_category).then((res: any) => {
          console.log('delete inventory item succ...', res)
          // message.success({ content: getFormattedMessage('delete'), key })
          setIsGlobalLoading(false)

          if (res.data && res.data.body && res.data.body.status) {
            message.warning({
              content: res.data.body.status, key,
              onClose: () => {
                getTableData()
              },
              duration: 3,
            });
          } else {
            message.success({
              content: getFormattedMessage('delete'), key,
              onClose: () => {
                getTableData()
              },
              duration: 1,
            });
          }

        }).catch(err => {
          console.log('Create inventory item fail...', err)
          setIsGlobalLoading(false)
          message.error({
            content: getFormattedMessage('delete', 'fail'), key,
            duration: 1,
          });
          // message.error({ content: getFormattedMessage('delete', 'fail'), key })
        })

      }
    })
  }

  const onTextSearch = (text: string, dataSource?: any) => {
    const tempData = dataSource || data
    const filtered: any = Array.isArray(tempData) && tempData.filter((item: any) => {
      const itemName = item?.item_name?.toLowerCase() || ''
      const sku = item?.sku?.toLowerCase() || ''
      const search = text?.toLowerCase() || ''
      return itemName.includes(search) || sku.includes(search)
    })
    setFilterData(filtered)


  }

  const createProductBtnOnClick = (e: MouseEvent) => {
    navigate(SPM_ROUTES.inventoryCreateProduct)
  }

  const categoryBtnOnClick = () => {
    navigate(SPM_ROUTES.inventoryProductsCategory)
  }


  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ margin: '10px 0px 0px 0px' }}>
        {/* <FlowInfoPanel
          items={[...PRODUCT_FLOW_STEPS]}
          style={{ minWidth: '600px', marginLeft: '15px' }}
        /> */}
      </div>
      <ProcessMap
        items={[...PRODUCT_FLOW_STEPS.map((item: any) => item.label)]} />

      {
        !isLoading && filterData?.length === 0 ?
          <EmptyDisplay data={{
            title: 'Start managing your Products.', text: 'Define, add, edit, and delete products',
            buttons: [<SimpleButton
              text='+ Create A Product'
              onClick={createProductBtnOnClick}
            />],
          }} />

          :
          <TableCard>
            <div style={{
              color: 'white',
              margin: '0 10px',
            }}>
              <Row gutter={[0, 15]} align="middle" justify="space-between">
                <Col>
                  <Row gutter={[15, 15]} align="bottom">
                    <Col>
                      <Input
                        type="search"
                        placeholder="Search by Product Name or Inventory ID"
                        onSearch={() => onTextSearch(searchText)}
                        onChange={(e: any) => {
                          console.log(e.target.value)
                          setSearchText(e.target.value)

                          if (!e.target.value) {

                            onTextSearch('')
                          }

                        }}
                        style={{ width: '300px' }}
                        value={searchText}
                        allowClear
                      />
                    </Col>

                  </Row>
                </Col>

                <Col style={{ display: 'flex', alignItems: 'end', gap: '10px' }}>
                  {/* <ColumnSelectButton
                  selectedColumns={selectedTableColumns}
                  columnOptions={selectColumnOptions}
                  updateSelectedColumns={(values: string[]) => setSelectedTableColumns(values)}
                /> */}

                  <SimpleButton
                    text='Category'
                    onClick={categoryBtnOnClick}
                    outlined
                  />
                  <SimpleButton
                    text={<BtnText><PlusOutlined />&nbsp;&nbsp;Create A Product </BtnText>}
                    onClick={createProductBtnOnClick}
                    style={{ marginTop: '5px', padding: '0px 15px' }}
                  />
                </Col>
              </Row>
            </div>
            <AdiTable
              loading={isLoading}
              columns={finalTableColumns}
              tableData={filterData}
              fullWidth
              marginTop='20px'
              scroll={{ x: '100%', y: '70vh' }}
              extraPaginationPadding
              onRowClick={(record: any, rowIndex: any, e: any) => {
                e.stopPropagation()
                navigate({
                  pathname: `${OSM_ROUTES.inventoryCreateProduct}`,
                  search: `?sku=${record.sku}&viewOnly=true`
                });
              }}
            // sticky
            />
          </TableCard>
      }



    </div>

  )
}

export default Items;


const BtnText = styled.span`
  color: var(--white-text-color);
  display: flex;
  align-items: center;
`
